// LoadingSpinner.js
import React from "react";
// import "./LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className="spinner-overlay">
      <div className="loader"></div>
    </div>
  );
};

export default LoadingSpinner;

/*
  useEffect(() => {
    // 데이터를 불러오거나 특정 작업 후 로딩 완료로 설정
    const timer = setTimeout(() => setIsLoading(false), 2000); // 예: 2초 후 로딩 완료
    return () => clearTimeout(timer);
  }, []);
*/
