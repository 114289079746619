import React from "react";

const Vision = () => {
  return (
    <div className="vision-container">
      <div className="vision-contents">
        <div>
          <div className="subtitle">비전</div>
          <div className="vision-wrap">
            <h2 style={{ color: "#999999" }}>
              보다 효율적이고 직관적인 IT 공부를 위한
            </h2>
            <h2 style={{ fontWeight: 800, fontSize: "2.5rem" }}>
              <span className="highlight">IT</span> 지식 공유 사이트
            </h2>

            <p className="vision-text">
              IT 기술을 배우고자 하는 사람이 전공 유무에 관계없이 직관적으로
              배울 수 있는 곳을 만들고자 합니다.
            </p>
            <div className="keyword-container">
              <span className="keyword-circle c1">DATA</span>
              <span className="keyword-circle c3">NETWORK</span>
              <span className="keyword-circle c5">DEVELOP</span>
            </div>
          </div>
        </div>
        <div>
          <div className="subtitle">목표</div>
          <p className="paragraph">
            넓은 세계에서 다양한 무공을 갈고 닦는 것이 마치 IT 기술의 공부
            과정과 유사하다고 생각합니다. 무림에서 고수가 되기 위해서
            <span className="highlight">내공을 단련</span>하듯, IT 기술을 갈고
            닦을 수 있는 홈페이지를 만들고 싶습니다.
          </p>
          <p className="paragraph">
            다양한 이치를 바탕으로 각자의 초식을 통해 단련해나가듯, IT
            세계에서는
            <span className="highlight">AI, DB, 인프라, 네트워크, 보안</span> 등
            다양한 분야에 대해 각자의 프로그래밍언어와 개념을 단련해 나갑니다.
          </p>
          <p className="paragraph">
            무공에서는 고수가 되기 위해서는 내공과 그릇이 중요하다고 합니다.
            <span className="highlight">내공의 깊이</span>는 단순히 아는 지식이
            많은게 아니라 <span className="highlight">지식의 깊이</span>에
            있다고 생각 합니다. 또한 내공이 뛰어나도 그릇의 문제로 절정에
            도달하지 못하는 경우도 있습니다.
          </p>
          <p className="paragraph">
            해당 홈페이지를 통해 정보기술로부터 뻗어져 나오는
            <span className="highlight">기초 지식을 빠르고 탄탄히</span> 다져
            좋은 그릇을 가질 수 있었으면 합니다.
          </p>

          <div className="keyword-container">
            <span className="keyword-circle c1">AI</span>
            <span className="keyword-circle c3">DB</span>
            <span className="keyword-circle c5">INFRA</span>
          </div>
          <div className="keyword-container">
            <span className="keyword-circle c2">NETWORK</span>
            <span className="keyword-circle c4">SECURITY</span>
          </div>
        </div>
        <div>
          <div className="subtitle">지원</div>
          <p className="paragraph">
            무림에서는 각 문파마다의 비급을 두어 내공 상승을 지원합니다. 저는
            해당 홈페이지를 통해
            <span className="highlight">강의나 블로그 책</span>을 통해 학습을
            지원할 예정입니다.
          </p>
          <div className="keyword-container">
            <span className="keyword-circle c1">책/강의</span>
            <span className="keyword-circle c3">게시글</span>
            <span className="keyword-circle c5">실습</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
