import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DiscussionEmbed } from "disqus-react";
import axios from "axios";
import hljs from "highlight.js";
import "highlight.js/styles/default.css";
import { getPostDataByLink } from "../../api/PostAPI";

const PostRead = () => {
  const { link } = useParams();
  const [postData, setPostData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getPostDataByLink(encodeURIComponent(link), setPostData);
    // 로컬 스토리지에서 데이터 불러오기
    // const dataset = JSON.parse(localStorage.getItem("postData")) || {};
    // if (dataset[link]) {
    //   const savedTitle = dataset[link].title;
    //   const savedContent = dataset[link].content;
    //   setPostData({ savedTitle, savedContent });
    // }
  }, []);

  const handleEditPost = () => {
    navigate(`/post/write/${link}`); // 수정 페이지로 이동
  };

  return (
    <div
      style={{
        // margin: "40px 20vw",
        padding: "40px 20vw",
        height: "100%",
        // borderRadius: "7px",
        backgroundColor: "#fbfbfb",
        // border: "1px solid lightgray",
      }}
    >
      {postData ? (
        <>
          <div style={{ boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)" }}>
            <div
              style={{
                padding: "20px",
                paddingBottom: "0px",
                minHeight: "60vh",
                backgroundColor: "#ffffff",
                // boxShadow:
                // "5px 0 10px rgba(0, 0, 0, 0.1), -5px 0 10px rgba(0, 0, 0, 0.1)", // 그림자 추가
                // border: "1px solid lightgray",
              }}
            >
              <h1
                style={{
                  borderBottom: "1px solid #f0f0f0",
                  padding: "10px 20px",
                  margin: "0px",
                }}
              >
                {postData.title}
              </h1>
              <div style={{ display: "flex", paddingLeft: "20px" }}>
                <p style={{ margin: "0px", color: "#b0b0b0" }}>날짜</p>
                <p style={{ margin: "0px", color: "#b0b0b0" }}>조회수</p>
              </div>
              <div
                style={{
                  paddingTop: "0px",
                  paddingLeft: "30px",
                  overflow: "auto",
                }}
                dangerouslySetInnerHTML={{ __html: postData.content }}
              />
            </div>
            <div
              style={{
                padding: "0px 20px",
                borderTop: "1px solid #f0f0f0",
                backgroundColor: "#ffffff",
              }}
              onClick={handleEditPost}
            >
              게시글 수정
            </div>
          </div>
          <DiscussionEmbed
            shortname="cjcho-blog"
            config={{
              url: `https://blog.cjcho.site/post/read/${link}`,
              identifier: link,
              title: link,
              language: "ko",
            }}
          />
          {/* <div
            style={{
              marginTop: "10px",
              padding: "10px 20px",
              minHeight: "100px",
              backgroundColor: "#ffffff",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            댓글
          </div> */}
        </>
      ) : (
        <>
          <p>저장된 게시물이 없습니다.</p>
        </>
      )}
    </div>
  );
};

export default PostRead;
