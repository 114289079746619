import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
import Cookies from "js-cookie";

/* Constant */
import URL from "../constants/url";

/* Layout */
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ChatBotBox from "../components/ChatBot/ChatBotBox";

/* Page */
import Home from "../pages/Home";
// intro
import Vision from "../pages/Intro/Vision";
import LoadMap from "../pages/Intro/LoadMap";
import AboutMe from "../pages/Intro/AboutMe";
import Admin from "../pages/Admin/Admin";
// Dashboard
import LabelingPage from "../pages/Dashboard/LabelingPage";
import Stock from "../pages/Dashboard/Stock";
import Weather from "../pages/Dashboard/Stock";
// Post
import PostPage from "../pages/Post/PostPage";
import PostWrite from "../pages/Post/PostWrite";
import PostRead from "../pages/Post/PostRead";

// Resource
import Resource from "../pages/Ref/Resource";

// community
import QA from "../pages/Community/QA";

// experiment
import OnnxExample from "../pages/Experiment/OnnxExample";
import Todo from "../pages/Experiment/Todo";
import WebCam from "../pages/Experiment/WebCam";

/* ETC */
import Search from "../pages/ETC/Search";
import Test from "../pages/ETC/Test";

// Personal
import CrtAcnt from "../pages/ETC/Personal/CrtAcnt";
import InfoEdit from "../pages/ETC/Personal/InfoEdit";
import Login from "../pages/ETC/Personal/Login";

import { fetchProtectedData } from "../api/auth";
import PaintBox from "../components/PaintCanvas/PaintBox";
const RootRoutes = () => {
  const location = useLocation();
  const jwtAuthentication = useCallback(() => {
    console.group("jwtAuthentication");
    console.log("[Start] jwtAuthentication ------------------------------");

    const jwtAuthURL = "/jwtAuthAPI";
    let requestOptions = {
      method: "POST",
    };

    // EgovNet.requestFetch(jwtAuthURL, requestOptions, (resp) => {
    //   if (resp === false) {
    //     setMounted(false);
    //   } else {
    //     setMounted(true); // 이 값으로 true 일 때만 페이지를 렌더링이 되는 변수 사용.
    //   }
    // });

    console.log("------------------------------jwtAuthentication [End]");
    console.groupEnd("jwtAuthentication");
  }, []);

  //시스템관리 메뉴인 /admin/으로 시작하는 URL은 모두 로그인이 필요하도록 코드추가(아래)
  const isMounted = useRef(false); // 아래 로그인 이동 부분이 2번 실행되지 않도록 즉, 마운트 될 때만 실행되도록 변수 생성
  const [mounted, setMounted] = useState(false); // 컴포넌트 최초 마운트 후 리렌더링 전 로그인 페이지로 이동하는 조건으로 사용

  useEffect(() => {
    if (!isMounted.current) {
      // 컴포넌트 최초 마운트 시 페이지 진입 전(렌더링 전) 실행
      isMounted.current = true; // 이 값으로 true 일 때만 페이지를 렌더링이 되는 변수 사용.
      setMounted(true); // 이 값으로 true 일 때만 페이지를 렌더링이 되는 변수 사용.
      const regex = /^(\/admin\/)+(.)*$/; //정규표현식 사용: /admin/~ 으로 시작하는 경로 모두 포함
      if (regex.test(location.pathname)) {
        setMounted(false); // 이 값으로 true 일 때만 페이지를 렌더링이 되는 변수 사용. 기본은 숨기기
        jwtAuthentication(); // 이 함수에서 관리자단 인증여부 확인 후 렌더링 처리
      }
    }
  }, [jwtAuthentication, location, mounted]); // location 경로와 페이지 마운트상태가 변경 될 때 업데이트 후 리렌더링

  if (mounted) {
    // 인증 없이 시스템관리 URL로 접근할 때 렌더링 되는 것을 방지하는 조건추가.
    return (
      <Routes>
        {/* <Route path={URL.ERROR} element={<EgovError />} /> */}
        <Route path="*" element={<SecondRoutes />} />
      </Routes>
    );
  }
};

const SecondRoutes = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);
  const [isPaintBoxOpen, setIsPaintBoxOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const [headerHeight, setHeaderHeight] = useState(0);
  const navigate = useNavigate();
  const isMounted = useRef(false); // 아래 로그인 이동 부분이 2번 실행되지 않도록 즉, 마운트 될 때만 실행되도록 변수 생성
  useEffect(() => {
    if (!isMounted.current) {
      // 컴포넌트 최초 마운트 시 페이지 진입 전(렌더링 전) 실행
      isMounted.current = true; // 이 값으로 true 일 때만 페이지를 렌더링이 되는 변수 사용.
    } else {
      // initPage();
    }
  }, []);
  // console.log("isLoggedIn", isLoggedIn);
  useEffect(() => {
    const header = document.querySelector("header");
    const updateHeights = () => {
      if (header) {
        setHeaderHeight(header.offsetHeight);
      }
    };

    updateHeights();
    window.addEventListener("resize", updateHeights);

    return () => {
      window.removeEventListener("resize", updateHeights);
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await fetchProtectedData();
      if (response) {
        setIsLoggedIn(response.status === 200);
      } else {
        setIsLoggedIn(false);
      }
    };
    getData();
  }, []);

  const ProtectedRoute = ({ isAdmin, children }) => {
    if (!isAdmin) {
      return <Navigate to={URL.LOGIN} replace />;
    }
    return children;
  };

  const toggleChatBot = () => {
    setIsChatBotOpen(!isChatBotOpen);
  };
  const togglePaintBox = () => {
    setIsPaintBoxOpen(!isPaintBoxOpen);
  };

  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />
      <main
        id="main-contents"
        style={{
          marginTop: `${headerHeight}px`,
          marginBottom: "46px",
        }}
      >
        <Routes>
          {/* page */}
          <Route path={URL.HOME} element={<Home />} />
          <Route path={URL.VISION} element={<Vision />} />
          <Route path={URL.LOADMAP} element={<LoadMap />} />
          <Route path={URL.ABOUTME} element={<AboutMe />} />

          {/* dashboard */}
          <Route path={URL.LABELING} element={<LabelingPage />} />
          <Route path={URL.STOCK} element={<Stock />} />
          <Route path={URL.WEATHER} element={<Weather />} />

          {/* post */}
          <Route path={URL.POST} element={<PostPage />} />
          <Route
            path={URL.POST_WRITE}
            element={<PostWrite isLoggedIn={isLoggedIn} isAdmin={isAdmin} />}
          />
          <Route
            path={URL.POST_WRITE_BASE}
            element={<PostWrite isLoggedIn={isLoggedIn} isAdmin={isAdmin} />}
          />
          <Route path={URL.POST_READ} element={<PostRead />} />

          {/* resource */}
          <Route path={URL.RES} element={<Resource />} />

          {/* cummunity */}
          <Route path={URL.QA} element={<QA />} />

          {/* experiment */}
          <Route path={URL.ONNX} element={<OnnxExample />} />
          <Route path={URL.TODO} element={<Todo />} />
          <Route path={URL.WEBCAM} element={<WebCam />} />

          {/* Others */}
          <Route path={URL.CRT_ACNT} element={<CrtAcnt />} />
          <Route path={URL.PERSONAL_INFO} element={<InfoEdit />} />

          <Route path={URL.SEARCH} element={<Search />} />
          <Route
            path={URL.LOGIN}
            element={
              <Login
                isLogin={isLoggedIn}
                setIsLogin={setIsLoggedIn}
                setIsAdmin={setIsAdmin}
              />
            }
          />
          {/* <Route path={URL.TEST} element={<Test />} /> */}
          <Route
            path={URL.ADMIN}
            element={
              <ProtectedRoute isAdmin={isAdmin}>
                <Admin />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
      <ChatBotBox isOpen={isChatBotOpen} toggle={toggleChatBot} />
      <PaintBox isOpen={isPaintBoxOpen} toggle={togglePaintBox} />
      <Footer />
      {activeItem && (
        <div className="overlay" onClick={() => setActiveItem(null)} />
      )}
    </>
  );
};
export default RootRoutes;
/*
https://github.com/eGovFramework/egovframe-template-simple-react/blob/contribution/src/routes/index.jsx 
*/
