import React from "react";
// import "./ChatButton.css";

const ChatBotButton = ({ onClick }) => {
  return (
    <button className="chatbot-button" onClick={onClick}>
      💬
    </button>
  );
};

export default ChatBotButton;
