import { useEffect, useRef, useState, useMemo } from "react";
import PostList from "../../components/Post/PostList";
import { useNavigate } from "react-router-dom";
import MindMap from "../../components/MindMap/MindMap";
import { getPostsData, getTagsData } from "../../api/PostAPI";

const PostPage = () => {
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const [tags, setTags] = useState([]);
  const [postsData, setPostsData] = useState([]);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isMindMapView, setIsMindMapView] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);

  // 높이 조절
  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef.current?.clientHeight]);

  // 태그 및 postData가져오기
  const fetchData = async () => {
    if (tags.length === 0) {
      await getTagsData(setTags);
    }

    // 게시글 데이터 가져오기
    const postRes = await getPostsData();
    const filteredPostIds = tags
      .filter(
        (item) => selectedTags.includes(item.name) && item.post_id !== null
      )
      .map((item) => item.post_id);

    setPostsData(postRes.filter((post) => filteredPostIds.includes(post.id)));
  };

  useEffect(() => {
    fetchData();
  }, [selectedTags]);

  // 태그 데이터가 로드된 후 selectedTags를 모든 태그 이름으로 초기화
  useEffect(() => {
    if (tags.length > 0) {
      setSelectedTags(tags.map((tag) => tag.name));
    }
  }, [tags]);

  // const uniqueTagCounts = tags.reduce((acc, tag) => {
  //   // 각 name에 대해 개수를 세기
  //   acc[tag.name] = (acc[tag.name] || 0) + 1;
  //   return acc;
  // }, {});
  const uniqueTagCounts = tags.reduce((acc, tag) => {
    if (tag.name !== "None") {
      // "None" 태그 제외
      acc[tag.name] = (acc[tag.name] || 0) + 1;
    }
    return acc;
  }, {});

  const toggleTag = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag]
    );
  };

  // 게시글 작성 버튼 클릭 핸들러
  const handleCreatePost = () => {
    navigate("/post/write");
  };

  let content;
  if (isMindMapView) {
    const transformDataArray = (dataArray) => {
      return {
        nodes: dataArray.map((data) => ({
          id: data.title, // id를 data.title로 설정
          label: data.title,
          svg: data.image,
        })),
        links: dataArray
          .map((data) => ({
            source: data.source, // 각 데이터의 source
            target: data.target, // 각 데이터의 target
          }))
          .filter((link) => link.source && link.target),
      };
    };
    const inputData = transformDataArray(postsData);
    content = (
      <MindMap
        data={{
          nodes: inputData.nodes,
          links: inputData.links,
        }}
      />
    );
  } else if (postsData.length === 0) {
    // 게시글이 없을 때
    content = (
      <div className="postlist-wrap">
        <div className="no-posts-message">게시글이 없습니다.</div>
      </div>
    );
  } else {
    // 게시글이 있을 때
    content = (
      <div>
        <PostList posts={postsData} initialCount={10} />
      </div>
    );
  }
  return (
    <>
      <div className="posting-wrap">
        {content}
        <div
          className="post-tag-wrap"
          style={{
            marginTop: headerHeight + "80px",
            position: "fixed",
          }}
        >
          <div className="post-tag-title">Tags</div>
          <div className="post-tag-values">
            <ul>
              {Object.entries(uniqueTagCounts).map(([tag, count]) => (
                <li
                  key={tag}
                  onClick={() => toggleTag(tag)}
                  style={{ cursor: "pointer" }}
                >
                  <p>{tag}</p>
                  <p
                    className="post-tag-count"
                    style={{
                      color: selectedTags.includes(tag) ? "#ff6b6b" : "#555",
                      marginLeft: "40px",
                    }}
                  >
                    {count}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* 마인드맵뷰 토글 버튼 추가 */}
        <div className={`mind-map-toggle ${isMindMapView ? "active" : ""}`}>
          <label>
            <span>마인드맵뷰</span>
            <input
              type="checkbox"
              checked={isMindMapView}
              onChange={() => setIsMindMapView((prev) => !prev)}
              style={{ marginLeft: "10px", display: "none" }}
            />
          </label>
        </div>
        {/* 게시글 작성 버튼 추가 */}
        <button
          className="create-post-button"
          onClick={handleCreatePost}
          style={{
            position: "fixed",
            right: "15vw",
            bottom: "10vh",
            backgroundColor: "#ff4d4d",
            color: "white",
            border: "none",
            borderRadius: "8px",
            padding: "10px 20px",
            cursor: "pointer",
            zIndex: 2, // 다른 요소 위에 위치하도록 설정
          }}
        >
          게시글 작성
        </button>
      </div>
    </>
  );
};

export default PostPage;
