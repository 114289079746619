import axios from "axios";
import URL from "../constants/url";

export const CreateUser = async (postData) => {
  try {
    await axios.post(`${URL.NODE_API}/user/create-user`, postData); // API URL
    alert("계정생성에 성공하셨습니다.");
  } catch (error) {
    alert("존재하는 사용자입니다.");
    console.error("데이터 가져오기 실패:", error);
  }
};

export const CheckedLogin = async (Data, setLogin, setIsAdmin) => {
  // todo : 관리자권한 컬럼 유저 테이블에 추가하고 관리할 것
  // console.log(`${URL.NODE_API}/user/login`, Data);
  try {
    const response = await axios.post(`${URL.NODE_API}/user/login`, Data);
    setLogin(true);
    localStorage.setItem("accessToken", response.data.accessToken);
    localStorage.setItem("refreshToken", response.data.refreshToken);

    // console.log("성공");
    const isAdmin = Data.username == "cjcho";
    // console.log("Data", Data);
    setIsAdmin(isAdmin);
  } catch (error) {
    setLogin(false);
    // console.error("실패");
    alert("서버가 종료되어 있거나, 로그인에 실패하셨습니다.");
  }
};
