import React, { useRef, useState, useEffect } from "react";
import HandwritingCanvas from "../Image/HandwritingCanvas";
const PaintBoxWindow = () => {
  const paintBoxRef = useRef(null); // 참조 생성
  const [boxDimensions, setBoxDimensions] = useState({
    width: 500,
    height: 400,
  });

  const handleMouseDown = (e) => {
    // e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = parseInt(
      document.defaultView.getComputedStyle(paintBoxRef.current).width,
      10
    );
    const startHeight = parseInt(
      document.defaultView.getComputedStyle(paintBoxRef.current).height,
      10
    );

    const doDrag = (e) => {
      const newWidth = startWidth - (e.clientX - startX); // 변경된 너비 계산
      const newHeight = startHeight - (e.clientY - startY); // 변경된 높이 계산

      // 최소 너비와 높이 확인
      if (newWidth >= 250) {
        paintBoxRef.current.style.width = `${newWidth}px`;
      }
      if (newHeight >= 350) {
        paintBoxRef.current.style.height = `${newHeight}px`;
      }
      setBoxDimensions({
        width: newWidth,
        height: newHeight,
      });
    };

    const stopDrag = () => {
      document.documentElement.removeEventListener("mousemove", doDrag);
      document.documentElement.removeEventListener("mouseup", stopDrag);
    };

    document.documentElement.addEventListener("mousemove", doDrag);
    document.documentElement.addEventListener("mouseup", stopDrag);
  };

  useEffect(() => {
    if (paintBoxRef.current) {
      const initialWidth = paintBoxRef.current.offsetWidth;
      const initialHeight = paintBoxRef.current.offsetHeight;

      setBoxDimensions({
        width: initialWidth,
        height: initialHeight,
      });
    }
  }, []);

  return (
    <div className="paint-box-window" ref={paintBoxRef}>
      <div className="resize-handle" onMouseDown={handleMouseDown}></div>
      <HandwritingCanvas
        width={boxDimensions.width}
        height={boxDimensions.height}
      />
    </div>
  );
};

export default PaintBoxWindow;
