import * as onnx from "onnxjs";

const LoadModel = async ({ model, backend = onnx.backend.webgl }) => {
  try {
    const session = new onnx.InferenceSession({ backend: backend });
    await session.loadModel(model);
    return session;
  } catch (error) {
    console.error("모델 로드 중 오류 발생:", error);
    throw error; // 오류를 다시 던져 호출한 곳에서 처리할 수 있게 함
  }
};

export default LoadModel;
