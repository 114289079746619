import { useState } from "react";

import FileUploader from "../../components/Image/Uploader/FileUploader";
import * as onnx from "onnxjs";

import mnistModel from "../../assets/onnx/mnist.onnx";
import raccoonModel from "../../assets/onnx/raccoon.onnx";
import catdogModel from "../../assets/onnx/cat_and_dog.onnx";
import LoadModel from "../../components/Onnx/LoadModel";
import ReadPixelData from "../../components/Onnx/ReadPixelData";
import RGBAtoGRAY from "../../components/Onnx/RGBAtoGray";
import RGBAtoRGB from "../../components/Onnx/RGBAtoRGB";
import ArrayToDiv from "../../components/Onnx/ArrayToDiv";
import YoloToXywh from "../../components/Onnx/YoloToXywh";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const OnnxExample = () => {
  const [uploadFiles, setUploadFiles] = useState([]);
  //   const [resizeImage, setResizeImage] = useState(null);
  const [resizeImages, setResizeImages] = useState([]); // 배열로 상태 변경
  const [isLoading, setIsLoading] = useState(false);

  const [onnxResults, setOnnxResults] = useState([]);
  const [modelType, setModelType] = useState([]);

  const validExtensions = ["jpg", "jpeg", "png", "gif"];
  const ImageUrls = Array.from(uploadFiles)
    .filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return validExtensions.includes(extension);
    })
    .map((file) => URL.createObjectURL(file));

  const mnistPredict = async () => {
    if (!uploadFiles || uploadFiles.length === 0) {
      alert("데이터를 업로드하시고 업로드 버튼을 눌러주세요.");
      return;
    }
    setIsLoading(true);
    setResizeImages([]);
    const session = await LoadModel({ model: mnistModel });

    // FileList를 배열로 변환
    const filesArray = Array.from(uploadFiles);

    // 모든 파일 처리 비동기 실행
    const results = await Promise.all(
      filesArray.map(async (file) => {
        const arr = await ReadPixelData(file, 28, 28);
        // setResizeImage(arr);
        setResizeImages((prev) => [...prev, arr]); // 이전 배열에 추가

        const grayArr = RGBAtoGRAY(arr);
        const inputTensor = new onnx.Tensor(grayArr, "float32", [
          1,
          1,
          arr.width,
          arr.height,
        ]);
        const outputMap = await session.run([inputTensor]);
        const outputTensor = outputMap.values().next().value;
        const predictions = outputTensor.data;

        return predictions.indexOf(Math.max(...predictions));
      })
    );
    setOnnxResults(results);
    setModelType("classification");

    setIsLoading(false);
    console.log("Result", results);
  };
  const catdogPredict = async () => {
    if (!uploadFiles || uploadFiles.length === 0) {
      alert("데이터를 업로드하시고 업로드 버튼을 눌러주세요.");
      return;
    }
    setIsLoading(true);
    setResizeImages([]);

    const session = await LoadModel({ model: catdogModel });

    const filesArray = Array.from(uploadFiles);

    // 모든 파일 처리 비동기 실행
    const results = await Promise.all(
      filesArray.map(async (file) => {
        const arr = await ReadPixelData(file, 224, 224);
        // setResizeImage(arr);
        setResizeImages((prev) => [...prev, arr]); // 이전 배열에 추가

        const rgbArr = RGBAtoRGB(arr);
        const inputTensor = new onnx.Tensor(
          rgbArr,
          "float32",
          [1, 224, 224, 3]
        );
        const outputMap = await session.run([inputTensor]);
        const outputTensor = outputMap.values().next().value;
        const predictions = outputTensor.data;
        return predictions[0] > 0.5 ? "고양이" : "강아지"; // 고양이 또는 강아지 예측
      })
    );
    setIsLoading(false);
    setOnnxResults(results);
    setModelType("classification");

    console.log("Result", results);
  };

  const raccoonPredict = async () => {
    if (!uploadFiles || uploadFiles.length === 0) {
      alert("데이터를 업로드하시고 업로드 버튼을 눌러주세요.");
      return;
    }

    setIsLoading(true);
    setResizeImages([]);

    const session = await LoadModel({ model: raccoonModel });

    const filesArray = Array.from(uploadFiles);
    // 모든 파일 처리 비동기 실행
    const results = await Promise.all(
      filesArray.map(async (file) => {
        const arr = await ReadPixelData(file, 224, 224);
        // setResizeImage(arr);
        setResizeImages((prev) => [...prev, arr]); // 이전 배열에 추가

        const rgbArr = RGBAtoRGB(arr);
        const inputTensor = new onnx.Tensor(
          rgbArr,
          "float32",
          [1, 224, 224, 3]
        );
        const outputMap = await session.run([inputTensor]);
        const outputTensor = outputMap.values().next().value;
        const predictions = outputTensor.data;
        const { x, y, w, h, prob } = YoloToXywh(predictions, 224);
        return { x, y, w, h, prob }; // YOLO 예측 결과
      })
    );
    setIsLoading(false);
    setOnnxResults(results);
    setModelType("detection");

    console.log("Result", results);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="onnx-example-wrap">
        <div className="subtitle">파일 업로드</div>
        <div className="onnx-file-uploader">
          <p> 클릭하여 업로드 시 랙이 발생할 수 있습니다.</p>
          <p> 손글씨 그림, 개 고양이 그림, 너구리 그림만 올려주세요.</p>
          <p>
            해당 페이지에서는 서버 GPU가 아닌 사용자의 GPU를 사용하고 있습니다.
          </p>
          <p> 따라서, 개인 GPU 사양에 따라 속도가 느릴 수 있습니다.</p>

          <div className="onnx-example-sample">
            <a href="/images/onnx_sample.zip" download="sample.zip">
              샘플 파일 다운로드
            </a>
          </div>

          <FileUploader
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
          />
        </div>
        <div className="onnx-preview">
          <p>Shift버튼을 누르고 마우스 휠하면 조금 더 편하게 볼 수 있습니다.</p>
          <div className="onnx-inference-button">
            <button onClick={mnistPredict}> mnist 추론 실행</button>
            <button onClick={catdogPredict}> cat and dog 추론 실행</button>
            <button onClick={raccoonPredict}> raccoon 추론 실행</button>
          </div>
          <div className="onnx-view-port">
            <div
              className="onnx-view-port-origin"
              style={{
                overflowX: "auto",
                height: "inherit",
                display: "flex",
                width: "40vw",
                margin: "0 30px",
              }}
            >
              <div
                style={{
                  width: "inherit",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <h2>ORIGIN</h2>
                {uploadFiles.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      width: "auto",
                      height: "inherit",
                    }}
                  >
                    {/* ViewPort를 감싸는 div */}
                    {ImageUrls.map((imageUrl, index) => (
                      <div
                        key={index}
                        style={{ marginRight: "10px", textAlign: "center" }}
                      >
                        <canvas
                          id={`canvas-${index}`} // 고유 ID를 부여
                          style={{
                            marginBottom: "5px",
                            border: "1px solid black",
                          }}
                          ref={(canvas) => {
                            if (canvas) {
                              const context = canvas.getContext("2d");
                              const img = new Image();
                              img.src = imageUrl;
                              img.onload = () => {
                                // 원본 크기로 캔버스 크기 설정
                                canvas.width = img.width;
                                canvas.height = img.height;
                                context.clearRect(
                                  0,
                                  0,
                                  canvas.width,
                                  canvas.height
                                ); // 기존 내용 지우기
                                context.drawImage(img, 0, 0); // 캔버스에 이미지 그리기

                                // 캔버스의 높이에 따라 클래스 추가
                                if (canvas.height < 200) {
                                  canvas.classList.add("small-canvas");
                                } else if (canvas.height < 400) {
                                  canvas.classList.add("medium-canvas");
                                } else {
                                  canvas.classList.add("large-canvas");
                                }

                                // 캔버스 아래에 너비와 높이 표시
                                const width = img.width;
                                const height = img.height;

                                // 기존의 p 요소가 있는 경우 제거
                                const existingP =
                                  canvas.parentElement.querySelector("p");
                                if (existingP) {
                                  existingP.remove();
                                }

                                // 새 p 요소 생성
                                const dimensionsElement =
                                  document.createElement("p");
                                dimensionsElement.textContent = `Width: ${width}px, Height: ${height}px`;
                                dimensionsElement.style.margin = "0"; // 여백 제거
                                canvas.parentElement.appendChild(
                                  dimensionsElement
                                ); // 캔버스 아래에 추가
                              };
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {/* {uploadFiles.length > 0 && (
            <ViewPort
              imagePaths={ImageUrls}
              style={{ width: "20vw", height: "inherit" }}
            />
          )} */}
            {/* {resizeImage && <ArrayToDiv imageData={resizeImage} />} */}
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                height: "inherit",
              }}
            >
              <h2>RESIZE</h2>
              <div
                className="onnx-view-port-resize"
                style={{
                  overflowX: "auto",
                  display: "flex",
                  width: "40vw",
                  height: "inherit",
                }}
              >
                {resizeImages.map((imageData, index) => (
                  <div
                    key={index}
                    className="image-container"
                    style={{
                      marginRight: "10px",
                      display: "flex", // Flexbox 사용
                      flexDirection: "column", // 세로 방향으로 배치
                      alignItems: "center", // 가로 중앙 정렬
                      justifyContent: "center", // 세로 중앙 정렬
                    }}
                  >
                    <ArrayToDiv imageData={imageData} />
                    <p style={{ margin: "0", textAlign: "center" }}>
                      Width: {imageData.width}px, Height: {imageData.height}px
                    </p>
                    {modelType === "classification" && (
                      <p>Claassification: {onnxResults[index]}</p>
                    )}
                    {modelType === "detection" && (
                      <>
                        <p>ObjectDetection:</p>
                        <p>
                          {`x: ${onnxResults[index].x.toFixed(2)}, 
                            y: ${onnxResults[index].y.toFixed(2)}, 
                            w: ${onnxResults[index].w.toFixed(2)}, 
                            h: ${onnxResults[index].h.toFixed(2)}, 
                            prob: ${onnxResults[index].prob.toFixed(3)}`}
                        </p>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* {resizeImages.map((imageData, index) => (
          <ArrayToDiv key={index} imageData={imageData} /> // 배열의 각 이미지 데이터 렌더링
        ))} */}
      </div>
    </>
  );
};
export default OnnxExample;
