import CameraRecorder from "../../components/CameraRecorder";
const WebCam = () => {
  return (
    <>
      <div>
        <CameraRecorder />
      </div>
    </>
  );
};

export default WebCam;
