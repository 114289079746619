import React, { useState, useRef, useEffect } from "react";

import { getGptData } from "../../api/PyAPI";

const ChatBotWindow = ({ savedKey, setSavedKey }) => {
  const [message, setMessage] = useState("");
  const [size, setSize] = useState({ width: 320, height: 450 });
  const [chatHistory, setChatHistory] = useState([]);
  const chatWindowRef = useRef(null);
  const textareaRef = useRef(null);
  const chatBodyRef = useRef(null);
  const [apiKey, setApiKey] = useState("");

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSend = async () => {
    if (message.trim() === "") return;

    // 사용자 메시지 추가
    const userMessage = { type: "user", content: message };
    setChatHistory((prev) => [...prev, userMessage]);
    if (apiKey !== "" || !message) {
      alert("API 키와 메시지를 모두 입력하세요.");
      return;
    }
    console.log("key", savedKey, message);
    const gpt_message = await getGptData({ key: savedKey, comment: message });
    // AI 응답 시뮬레이션
    setTimeout(() => {
      const aiMessage = { type: "ai", content: `GPT: ${gpt_message}` };
      setChatHistory((prev) => [...prev, aiMessage]);
    }, 1000);

    setMessage("");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleResize = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = size.width;
    const startHeight = size.height;

    const doDrag = (e) => {
      const newWidth = Math.max(250, startWidth + startX - e.clientX);
      const newHeight = Math.max(350, startHeight + startY - e.clientY);
      setSize({ width: newWidth, height: newHeight });
    };

    const stopDrag = () => {
      document.removeEventListener("mousemove", doDrag);
      document.removeEventListener("mouseup", stopDrag);
    };

    document.addEventListener("mousemove", doDrag);
    document.addEventListener("mouseup", stopDrag);
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.style.width = `${size.width}px`;
      chatWindowRef.current.style.height = `${size.height}px`;
    }
    if (textareaRef.current) {
      const newHeight = size.height - 130;
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, [size]);
  useEffect(() => {
    // 새 메시지가 추가될 때마다 스크롤을 아래로 이동
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleInputChange = (event) => {
    setApiKey(event.target.value);
  };
  const handleSave = () => {
    // API 키를 저장합니다.
    if (apiKey !== "") {
      setSavedKey(apiKey);
      console.log("Saved API Key:", apiKey); // 콘솔에 저장된 키 출력
      alert("API 키가 저장되었습니다!"); // 저장 성공 알림
      setApiKey(""); // 입력 필드 초기화
    } else {
      alert("API 키를 입력하시고 눌러주세요!");
    }
  };

  return (
    <div className="chat-window" ref={chatWindowRef}>
      <div className="resize-handle left" onMouseDown={handleResize}></div>
      <div className="chat-header">
        <div>
          <h2>ChatBot</h2>
          <input
            type="password"
            value={apiKey}
            onChange={handleInputChange}
            placeholder="gpt api key를 입력하세요"
          ></input>
          <button onClick={handleSave}>저장</button>
        </div>
      </div>
      <div className="chat-body" ref={chatBodyRef}>
        {chatHistory.length === 0 ? (
          <div className="chat-empty-message">
            해당 ChatBot은 채팅내용을 기억하지 않습니다.
          </div>
        ) : (
          chatHistory.map((msg, index) => (
            <div key={index} className={`chat-message ${msg.type}`}>
              {msg.content}
            </div>
          ))
        )}
      </div>
      <div className="chat-input-area">
        <textarea
          className="chat-input"
          ref={textareaRef}
          value={message}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="메시지를 입력하세요..."
        />
        <button className="send-msg-btn" onClick={handleSend}>
          전송
        </button>
      </div>
    </div>
  );
};

export default ChatBotWindow;
