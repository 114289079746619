import React, { useState } from "react";

// 가상의 데이터 (예: 블로그 포스트, 제품 등)
const data = [
  { id: 1, title: "React Tutorial" },
  { id: 2, title: "JavaScript Basics" },
  { id: 3, title: "CSS Grid Layout" },
  // 추가 데이터...
];

const Search = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);

  const handleSearch = () => {
    // 검색 결과 필터링
    const filteredResults = data.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setResults(filteredResults);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="검색어를 입력하세요..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button onClick={handleSearch}>검색</button>
      <ul>
        {results.map((item) => (
          <li key={item.id}>{item.title}</li>
        ))}
      </ul>
    </div>
  );
};

export default Search;
