import React, { useState } from "react";

import ChatBotWindow from "./ChatBotWindow";
import ChatBotButton from "./ChatBotButton";
const ChatBotBox = ({ isOpen, toggle }) => {
  const [savedKey, setSavedKey] = useState(""); // 저장된 키 상태

  return (
    <>
      <ChatBotButton onClick={toggle} />
      {isOpen && (
        <ChatBotWindow savedKey={savedKey} setSavedKey={setSavedKey} />
      )}
    </>
  );
};

export default ChatBotBox;
