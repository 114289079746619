import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Quill from "quill";
import hljs from "highlight.js";
// import "highlight.js/styles/default.css";
import "@/../highlight.js/styles/github.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import "highlight.js/styles/atom-one-dark.css";
import katex from "katex";
import "katex/dist/katex.min.css";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import MagicUrl from "quill-magic-url";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FileUploader from "../../components/Image/Uploader/FileUploader";
import PostWriteSettings from "./PostWriteSettings";
import {
  deletePostsData,
  fetchPostsData,
  getPostWriteDataByLink,
} from "../../api/PostAPI";
import { fetchProtectedData } from "../../api/auth";
import ImageResize from "quill-image-resize";
import { UploadData } from "../../api/UploadAPI";
import URL from "../../constants/url";

Quill.register("modules/magicUrl", MagicUrl);
Quill.register("modules/ImageResize", ImageResize);

hljs.configure({
  languages: ["javascript", "ruby", "python", "java", "cpp", "sql"],
});

window.katex = katex;

const PostWrite = ({ isLoggedIn, isAdmin }) => {
  const navigate = useNavigate();
  const { link } = useParams(); // URL에서 id 가져오기
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [linkState, setLinkState] = useState(""); // 변수 이름을 변경
  const [uploadFiles, setUploadFiles] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [select1, setSelect1] = useState("public");
  const [textAreaValue, setTextAreaValue] = useState("");
  const [gptResult, setGptResult] = useState("");
  const [tags, setTags] = useState([]);
  const [source, setSource] = useState("");

  const imageHandler = async () => {
    // console.log(";teasaaasta");

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("files", file);
        const res = await UploadData(formData);

        const range = quill.getSelection();
        const img = `<img src=${URL.DOMAIN}${res.data.files} alt="image"/>`;
        console.log(`${URL.DOMAIN}`);
        quill.clipboard.dangerouslyPasteHTML(range.index, img);
      }
    };
  };

  const handlePaste = async (e) => {
    // console.log(";aaa");
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file" && item.type.startsWith("image/")) {
        const file = item.getAsFile();
        const formData = new FormData();
        formData.append("image", file);
        // 클립보드에 있는 이미지를 Quill 에디터에 추가
        const res = await UploadData(formData);
        const range = quill.getSelection();
        const img = `<img src=${URL.DOMAIN}${res.data.files} alt="image"/>`;
        quill.clipboard.dangerouslyPasteHTML(range.index, img);

        // await UploadData(formData); // 업로드 처리
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn === false || isAdmin === false) {
      navigate("/");
      alert("관리자 계정으로 로그인 후 사용하시기 바랍니다.");
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef]);

  // editor 디자인
  const theme = "snow";
  const modules = {
    syntax: { hljs },
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true,
    },
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["emoji"],
      ["blockquote", "code-block"],
      ["link", "image", "video", "formula"],
      [{ align: [] }],
      [{ direction: "rtl" }],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }],

      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      ["clean"],
      [{ align: [] }],
    ],
    handlers: {
      image: imageHandler, // 이미지 핸들러 추가
    },
    ImageResize: { modules: ["Resize"] },

    clipboard: {
      matchVisual: false,
    },
    magicUrl: true, // Enable MagicUrl module here
  };

  const placeholder = "Compose an epic...";
  const formats = [
    "bold",
    "code-block",
    "blockquote",
    "italic",
    "font",
    "underline",
    "strike",
    "align",
    "list",
    "indent",
    "size",
    "header",
    "link",
    "image",
    "video",
    "color",
    "background",
    "emoji",
    "formula",
  ];

  const { quillRef, quill } = useQuill({
    theme,
    modules,
    formats,
    placeholder,
  });

  // quill 인스턴스가 존재할 때 imageHandler 추가
  useEffect(() => {
    if (quill) {
      quill.getModule("toolbar").addHandler("image", imageHandler);
      quill.root.addEventListener("paste", handlePaste); // 클립보드 이벤트 리스너 추가
    }
  }, [quill]);

  const handleViewPost = () => {
    navigate(`/post/read/${link}`); // 수정 페이지로 이동
  };

  // TODO : State로 N이 있는지 가지고 있기
  useEffect(() => {
    const fetchPostData = async () => {
      // console.log("link", link);
      if (link) {
        const _postsData = await getPostWriteDataByLink(
          link,
          quill,
          setTitle,
          setLinkState
        ); // await 추가
        if (_postsData) {
          setTitle(_postsData[0].title);
          setLinkState(link);
          const savedContent = _postsData[0].content;
          if (quill && savedContent) {
            quill.clipboard.dangerouslyPasteHTML(savedContent);
          }
        }
        // console.log("api return", _postsData);
      }
    };

    fetchPostData();
  }, [link, quill]);

  useEffect(() => {
    if (quill && gptResult) {
      quill.setText(gptResult);
    }
  }, [gptResult]);

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    // const sanitizedLink = sanitizeLink(newTitle);
    const linkFromTitle = encodeURIComponent(
      newTitle.trim().replace(/\s+/g, "-")
    );
    setLinkState(linkFromTitle);
  };

  const handleSave = () => {
    if (!title || !linkState) {
      alert("제목을 입력하세요");
    } else {
      if (quill) {
        const editorContent = quill.root.innerHTML;
        // todo : URL 인코딩 처리
        const postData = {
          title: title,
          content: editorContent,
          link: encodeURIComponent(link || title), // link 또는 title을 URL 인코딩
          // link: link || title, // link가 null이면 title을 사용
          is_public: select1 === "public",
          target: title, // title
          source: source,
          preview_image: uploadFiles
            ? uploadFiles
            : "/images/background/로드맵.jpg",
          tags: tags,
        };
        // console.log("postData", postData);
        fetchPostsData(postData);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "s") {
        e.preventDefault(); // 기본 동작을 방지합니다 (예: 페이지 저장)
        handleSave(); // 저장 함수 호출
      }
    };
    // 키 다운 이벤트 리스너 추가
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      // 언마운트 시 리스너 제거
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [title, linkState, quill]);

  const handleDelete = () => {
    if (!title) {
      alert("제목을 입력하세요");
    } else {
      deletePostsData(title);
    }
  };

  // left
  const toggleDiv = () => {
    setIsOpen(!isOpen); // 현재 상태를 반전시킴
  };

  // console.log(tags);
  return (
    <>
      <OpenInNewIcon
        onClick={toggleDiv}
        style={{
          marginLeft: "8px",
          position: "fixed",
          cursor: "pointer",
          right: "0",
          padding: "20px",
          zIndex: "3",
          transform: "scaleX(-1)",
        }}
      />
      {isOpen && (
        <PostWriteSettings
          select1={select1}
          setSelect1={setSelect1}
          setGptResult={setGptResult}
          fileName={uploadFiles}
          setFileName={setUploadFiles}
          tags={tags}
          setTags={setTags}
          source={source}
          setSource={setSource}
        />
      )}
      <div
        style={{
          // width: "40%",
          height: "50vh",
          margin: "30px 25vw",
        }}
      >
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={handleTitleChange}
          style={{
            width: "100%",
            padding: "8px 0px",
            fontSize: "16px",
            border: "1px solid lightgray",
          }}
        />
        {/* <input
          type="text"
          placeholder="링크"
          value={linkState}
          onChange={(e) => setLinkState(e.target.value)}
          style={{
            width: "50%",
            padding: "8px 0",
            border: "1px solid lightgray",
            fontSize: "16px",
          }}
        /> */}
        <div ref={quillRef} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#fafafa",
            padding: "10px 10px",
            justifyContent: "flex-end",
            border: "1px solid lightgray",
          }}
        >
          {/* <FileUploader
          uploadFiles={uploadFiles}
          setUploadFiles={setUploadFiles}
          accept="image/png, image/jpg"
        /> */}
          <div>
            <button
              onClick={handleSave}
              style={{
                height: "inherit",
                borderRadius: "10px",
                padding: "10px",
                fontSize: "16px",
                backgroundColor: "#ffcccc",
              }}
            >
              저장
            </button>

            <button
              onClick={handleDelete}
              style={{
                marginLeft: "10px",
                height: "inherit",
                borderRadius: "10px",
                padding: "10px",
                fontSize: "16px",
                backgroundColor: "#ffcccc",
              }}
            >
              삭제
            </button>
            <button
              onClick={handleViewPost}
              style={{
                marginLeft: "10px",
                height: "inherit",
                padding: "10px",
                borderRadius: "10px",
                fontSize: "16px",
                backgroundColor: "#ffcccc",
              }}
            >
              게시글 보기
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostWrite;
