import React, { useState, useCallback } from "react";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

const FileUploader = ({
  uploadFiles,
  setUploadFiles,
  isMultiple = false,
  accept = "image/*",
  direction = "row",
  apiUploadFunction = null,
  // accept = "image/png, image/jpeg",
}) => {
  const [localFiles, setLocalFiles] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const validateFileExtensions = (files) => {
    if (!accept) return true; // accept가 비어있으면 모든 파일 허용
    const acceptedExtensions =
      accept === "image/*"
        ? [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".svg"] // image/*일 때
        : accept
            .split(",")
            .map((ext) => ext.trim().toLowerCase()) // 사용자가 설정한 accept 값 처리
            .map((ext) => ext.replace("image/", ".")); // "image/png" -> ".png"

    // 파일 확장자 검증
    return Array.from(files).every((file) => {
      const fileExtension = `.${file.name.split(".").pop().toLowerCase()}`; // 파일 확장자 추출
      return acceptedExtensions.includes(fileExtension); // 허용된 확장자 목록에 있는지 확인
    });
  };

  const handleFileUpload = async () => {
    if (localFiles.length === 0) {
      alert("파일을 선택해주세요.");
      return;
    }
    // 파일 확장자 검증
    if (!validateFileExtensions(localFiles)) {
      alert("지원하지 않는 파일 형식입니다. 허용된 형식: " + accept);
      return;
    }

    setIsLoading(true);
    setUploadFiles(localFiles);
    const formData = new FormData();
    Array.from(localFiles).forEach((file) => {
      formData.append(`files`, file);
    });
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }
    /*
    const res = await axios.post(
        `http://190.65.215.23/${modelVersion}/upload/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
    )
    */
    if (apiUploadFunction) {
      try {
        const res = await apiUploadFunction(formData);
        setUploadFiles(res);
        console.log("Upload successful:", res.data);
      } catch (error) {
        console.error("Upload failed:", error);
        alert("파일 업로드에 실패했습니다.");
      }
    } else {
      const uploadedFilesData = Array.from(uploadFiles).map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      }));

      // LocalStorage에 저장
      localStorage.removeItem("uploadedFiles");
      localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFilesData));
    }
    setIsLoading(false);
  };

  const handleFileChange = useCallback((event) => {
    setIsLoading(true);
    const files = event.target.files;
    if (files.length > 0) {
      setLocalFiles(files);
      const names = Array.from(files).map((file) => file.name);
      setUploadedFileNames(names);
      //   setUploadFiles(files);

      // Array.from(files).forEach((file) => {
      //   console.log("File name:", file.name);
      //   console.log("File size:", file.size);
      //   console.log("File type:", file.type);
      //   console.log("Last modified:", file.lastModified);
      // });
    }
    setIsLoading(false);
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault(); // 기본 동작 방지
    event.stopPropagation(); // 이벤트 전파 방지
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      setLocalFiles(files);
      // Array.from(files).forEach((file) => {
      //   console.log("File name:", file.name);
      //   console.log("File size:", file.size);
      //   console.log("File type:", file.type);
      //   console.log("Last modified:", file.lastModified);
      // });
      const names = Array.from(files).map((file) => file.name);
      setUploadedFileNames(names);
    }
  };
  return (
    <>
      {isLoading && <LoadingSpinner />} {/* 로딩 스피너 표시 */}
      <div
        className="file-uploader-wrap"
        style={{ display: "flex", flexDirection: direction }}
      >
        <input
          className="file-uploader-input"
          type="file"
          multiple={isMultiple} // multiple 프로퍼티 사용
          accept={accept} // accept 프로퍼티 사용
          onChange={handleFileChange}
          style={{
            visibility: "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <div
          className="file-uploader-form"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => document.querySelector(".file-uploader-input").click()}
        >
          {uploadedFileNames.length > 0 ? (
            <span className="uploaded-file-names">
              {uploadedFileNames.join(", ").length > 50
                ? `${uploadedFileNames.join(", ").substring(0, 47)}...`
                : uploadedFileNames.join(", ")}
            </span>
          ) : (
            "파일을 드래그하거나 클릭하여 업로드"
          )}
        </div>
        <div
          className={`file-uploader-button ${
            direction === "column" ? "column" : ""
          }`}
          onClick={handleFileUpload}
        >
          업로드
        </div>
      </div>
    </>
  );
};

export default FileUploader;
