const RGBAtoRGB = (pixelData) => {
  const rgbData = new Array(pixelData.width * pixelData.height * 3);
  let dataIndex = 0;
  for (let i = 0; i < pixelData.data.length; i += 4) {
    rgbData[dataIndex++] = pixelData.data[i]; // Red
    rgbData[dataIndex++] = pixelData.data[i + 1]; // Green
    rgbData[dataIndex++] = pixelData.data[i + 2]; // Blue
  }
  const arr = new Float32Array(rgbData);
  return arr;
};
export default RGBAtoRGB;
