import axios from "axios";
import URL from "../constants/url";

// API 호출 함수
export const fetchOCRResult = async (formData) => {
  try {
    const response = await axios.post(`${URL.CV_API}/ocr`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data; // axios는 응답 데이터를 `data` 속성에 저장
  } catch (error) {
    console.error("OCR API 호출 오류:", error);
    return null;
  }
};
export const fetchSDResult = async (data) => {
  console.log(`${URL.SD_API}/sdapi/v1/txt2img`, data);
  try {
    const response = await axios.post(`${URL.SD_API}/sdapi/v1/txt2img`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data; // axios는 응답 데이터를 `data` 속성에 저장
  } catch (error) {
    console.error("OCR API 호출 오류:", error);
    return null;
  }
};
export const fetchSAMResult = async (formData) => {
  try {
    const response = await axios.post(`${URL.CV_API}/sam`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data; // axios는 응답 데이터를 `data` 속성으로 반환
  } catch (error) {
    console.error("SAM API 호출 오류:", error);
    throw error; // 호출 실패 시 에러를 호출한 함수로 전달
  }
};

// 미사용
export const getOcrData = async () => {
  try {
    const response = await axios.post(`${URL.CV_API}/ocr`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch OCR data", error);
    throw error;
  }
};

export const getSamData = async () => {
  try {
    const response = await axios.post(`${URL.CV_API}/sam`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch SAM data", error);
    throw error;
  }
};
export const generateImageFromText = async () => {
  try {
    const response = await axios.post(`${URL.SD_API}/v1/txt2img`);
    return response.data;
  } catch (error) {
    console.error("Failed to generate image from text", error);
    throw error;
  }
};
