import React from "react";

// InputField 컴포넌트 생성
const InputField = ({
  label,
  type,
  value,
  onChange,
  // pattern = "",
  placeholder = "",
  maxLength = "none",
  required = false,
  minLength = 0, // 최소 길이 속성 추가
}) => {
  return (
    <div className="input-field">
      <label>{label}:</label>
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        // pattern={pattern}
        maxLength={maxLength}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        minLength={minLength} // minLength 추가
      />
    </div>
  );
};
export default InputField;
