import React from "react";

const UnderConstruction = () => {
  return (
    <div className="under-container">
      <h1>개발중이에요</h1>
      <p>이 페이지는 현재 개발 중입니다. 잠시 후 다시 확인해 주세요!</p>
    </div>
  );
};

export default UnderConstruction;
