// LabelingPage.js
import React, { useState } from "react";

const LabelingPage = () => {
  const [image, setImage] = useState(null);
  const [annotations, setAnnotations] = useState([]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAnnotation = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    setAnnotations([...annotations, { x, y }]);
  };

  return (
    <div>
      <h1>레이블링 페이지</h1>
      <input type="file" onChange={handleImageUpload} />
      {image && (
        <div style={{ position: "relative" }}>
          <img
            src={image}
            alt="Upload"
            onClick={handleAnnotation}
            style={{ maxWidth: "100%" }}
          />
          {annotations.map((annotation, index) => (
            <div
              key={index}
              style={{
                position: "absolute",
                left: annotation.x,
                top: annotation.y,
                width: "10px",
                height: "10px",
                backgroundColor: "red",
                borderRadius: "50%",
              }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LabelingPage;
