// src/ResourceTable.js
import React, { useState } from "react";
import DynamicTable from "../../components/DynamicTable";
import FileUploader from "../../components/Image/Uploader/FileUploader";

const Resource = () => {
  const [isAdmin] = useState(true); // 관리자 여부
  const [selectedTags, setSelectedTags] = useState([]); // 선택된 태그 관리
  const [uploadFiles, setUploadFiles] = useState([]);
  const resources = [
    {
      id: 1,
      title: "자료 1",
      description: "첫 번째 자료",
      downloadLink: "#",
      tags: ["태그1", "태그2"],
    },
    {
      id: 2,
      title: "자료 2",
      description: "두 번째 자료",
      downloadLink: "#",
      tags: ["태그3"],
    },
    {
      id: 3,
      title: "자료 3",
      description: "세 번째 자료",
      downloadLink: "#",
      tags: ["태그1"],
    },
    // 추가 자료를 여기에 추가할 수 있습니다.
  ];

  const handleTagSelect = (tag) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((t) => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };

  const filteredResources = resources.filter(
    (resource) =>
      selectedTags.length === 0 ||
      resource.tags.some((tag) => selectedTags.includes(tag))
  );

  const allTags = [...new Set(resources.flatMap((resource) => resource.tags))];

  return (
    <div style={{ margin: "0 20vw" }}>
      <h2>자료실</h2>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {isAdmin && (
          <FileUploader
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
          />
        )}
      </div>
      {/* {isAdmin && <button onClick={handleUpload}>업로드</button>} */}
      <div>
        <h3>태그 선택:</h3>
        {allTags.map((tag) => (
          <label key={tag}>
            <input
              type="checkbox"
              checked={selectedTags.includes(tag)}
              onChange={() => handleTagSelect(tag)}
            />
            {tag}
          </label>
        ))}
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>제목</th>
            <th>설명</th>
            <th>다운로드</th>
            <th>태그</th>
          </tr>
        </thead>
        <tbody>
          {filteredResources.map((resource) => (
            <tr key={resource.id}>
              <td>{resource.id}</td>
              <td>{resource.title}</td>
              <td>{resource.description}</td>
              <td>
                <a href={resource.downloadLink}>다운로드</a>
              </td>
              <td>{resource.tags.join(", ")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Resource;
