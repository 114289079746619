const RGBAtoGRAY = (pixelData) => {
  const grayData = new Array(pixelData.width * pixelData.height);
  for (let i = 0; i < pixelData.data.length; i += 4) {
    const r = pixelData.data[i];
    const g = pixelData.data[i + 1];
    const b = pixelData.data[i + 2];
    const gray = 0.2989 * r + 0.587 * g + 0.114 * b;
    grayData[i / 4] = gray;
  }
  const arr = new Float32Array(grayData);
  return arr;
};
export default RGBAtoGRAY;
