import { fabric } from "fabric";
import { useEffect, useRef, useState } from "react";
import {
  FaMousePointer,
  FaPen,
  FaHandRock,
  FaTrash,
  FaUpload,
  FaDownload,
  FaSquare,
  FaStar,
  FaCut,
  FaFont,
} from "react-icons/fa"; // 아이콘 임포트
import { fetchSAMResult, fetchOCRResult, fetchSDResult } from "../../api/cvAPI";
// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
// import "./HandWritingCanvas.css";

const HandwritingCanvas = ({ width, height }) => {
  const canvasRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [activeTool, setActiveTool] = useState("select");
  const [brushColor, setBrushColor] = useState("#000000"); // 기본 색상
  const [brushWidth, setBrushWidth] = useState(10); // 기본 선 두께
  const [polygonPoints, setPolygonPoints] = useState([]);
  const [textInput, setTextInput] = useState(""); // 텍스트 입력 상태
  const [selectedTextObject, setSelectedTextObject] = useState(null); // 선택된 텍스트 객체 상태

  useEffect(() => {
    const canvasContainer = canvasContainerRef.current;
    const newCanvas = new fabric.Canvas(canvasRef.current, {
      // width: canvasContainer.offsetWidth,
      // height: canvasContainer.offsetHeight,
      width: canvasContainer.offsetWidth,
      height: canvasContainer.offsetHeight,

      preserveObjectStacking: true,
    });
    setCanvas(newCanvas);

    // Zoom
    newCanvas.on("mouse:wheel", function (opt) {
      const delta = opt.e.deltaY;
      let zoom = newCanvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      newCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    return () => {
      newCanvas.dispose();
    };
  }, []);

  useEffect(() => {
    if (canvas) {
      canvas.setWidth(parseInt(width, 10));
      canvas.setHeight(parseInt(height, 10));
      canvas.renderAll(); // 업데이트된 크기를 반영하여 다시 렌더링
    }
  }, [width, height, canvas]);

  //   Tool bar
  useEffect(() => {
    console.log(activeTool);

    if (!canvasRef.current || !canvas) return;

    canvas.off("mouse:down");
    canvas.off("mouse:move");
    canvas.off("mouse:up");

    switch (activeTool) {
      case "select":
        handleSelectTool();
        break;
      case "pen":
        handlePenTool();
        break;
      case "textbox":
        handleTextboxTool();
        break;
      case "hand":
        handleHandTool();
        break;
      case "rect":
        handleRectTool();
        break;
      case "polygon":
        handlePolygonTool();
        break;
      case "SAM":
        handleSAMTool();
        break;
    }
    // CTRL+A 이벤트 리스너 추가
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "a") {
        event.preventDefault(); // 기본 동작 방지
        canvas.discardActiveObject(); // 기존 선택 해제
        const allObjects = canvas.getObjects(); // 캔버스의 모든 객체 가져오기
        setActiveTool("select");
        const selection = new fabric.ActiveSelection(allObjects, {
          canvas: canvas,
        });
        canvas.setActiveObject(selection); // 모든 객체 선택
        canvas.renderAll(); // 캔버스 다시 그리기
      } else if (event.key === "1") {
        setActiveTool("select");
      } else if (event.key === "2") {
        setActiveTool("pen");
      } else if (event.key === "3") {
        setActiveTool("hand");
      } else if (event.key === "4") {
        setActiveTool("rect");
      } else if (event.key === "5") {
        setActiveTool("polygon");
      } else if (event.key === "Escape" && activeTool === "polygon") {
        setActiveTool("select");
        finalizePolygon();
      } else if (event.key === "Delete" || event.key === "Backspace") {
        deleteSelectedObjects();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown); // 언마운트 시 리스너 제거
    };
  }, [canvas, activeTool, polygonPoints]);

  const handleSelectTool = () => {
    canvas.hoverCursor = "default";

    canvas.selection = true;
    canvas.defaultCursor = "crosshair";
    canvas.isDrawingMode = false;
    canvas.on("mouse:down", function (event) {
      const pointer = canvas.getPointer(event.e); // 클릭한 위치의 좌표를 가져옵니다.
      console.log("X:", pointer.x, "Y:", pointer.y);
    });
  };
  const handleTextboxTool = () => {
    let isDrawing = false;
    let startX, startY;

    canvas.isDrawingMode = false; // 드로잉 모드 해제

    // 기존 이벤트 리스너 제거 (있는 경우)
    canvas.off("mouse:down");
    canvas.off("mouse:move");
    canvas.off("mouse:up");

    const handleMouseDown = (event) => {
      isDrawing = true; // 그리기 시작
      canvas.selection = false; // 선택 비활성화
      const pointer = canvas.getPointer(event.e);
      startX = pointer.x; // 시작 X좌표
      startY = pointer.y; // 시작 Y좌표
    };

    const handleMouseMove = (event) => {
      if (!isDrawing) return; // 그리기가 진행 중이지 않으면 종료
      const pointer = canvas.getPointer(event.e);
      // 현재 드래그 중인 영역을 표시하기 위해 선을 추가할 수 있지만, 생략했습니다.
    };

    const handleMouseUp = (event) => {
      if (!isDrawing) return; // 그리기가 진행 중이지 않으면 종료
      isDrawing = false; // 그리기 종료
      canvas.selection = true; // 선택 가능하게 설정

      const pointer = canvas.getPointer(event.e);
      const width = pointer.x - startX; // 너비
      const height = pointer.y - startY; // 높이

      // 텍스트 박스 생성
      const textBox = new fabric.Textbox(" ", {
        left: startX,
        top: startY,
        width: Math.abs(width), // 드래그한 너비
        height: Math.abs(height), // 드래그한 높이
        fontSize: 20,
        fill: "black",
        editable: true, // 편집 가능
        selectable: true, // 선택 가능
      });

      // 텍스트 박스의 위치 조정
      if (width < 0) {
        textBox.set({ left: startX + width });
      }
      if (height < 0) {
        textBox.set({ top: startY + height });
      }

      canvas.add(textBox); // 캔버스에 텍스트 박스 추가
      canvas.setActiveObject(textBox); // 방금 추가한 텍스트 박스를 활성화
      textBox.enterEditing(); // 텍스트 박스 편집 모드로 전환
      textBox.selectAll(); // 텍스트 박스의 모든 텍스트 선택
      canvas.renderAll(); // 캔버스 다시 렌더링
    };

    canvas.on("mouse:down", handleMouseDown);
    canvas.on("mouse:move", handleMouseMove);
    canvas.on("mouse:up", handleMouseUp);
  };

  const handleSAMTool = () => {
    canvas.isDrawingMode = false;
    canvas.defaultCursor = "default";

    if (activeTool === "SAM") {
      canvas.on("selection:created", async (event) => {
        const selectedObjects = event.selected;
        const objectInfoList = {
          images: [],
          others: [],
        };

        if (selectedObjects.length > 0) {
          console.log("시작");

          selectedObjects.forEach((object) => {
            const centerX = object.left + object.width / 2;
            const centerY = object.top + object.height / 2;

            if (object.type === "image" || object.type === "rect") {
              const imageData = object._element.src;
              const objectInfo = {
                type: object.type,
                left: object.left,
                right: object.left + object.width,
                top: object.top,
                bottom: object.top + object.height,
                image: imageData,
              };
              if (object.type === "image") {
                objectInfoList.images.push(objectInfo);
              } else {
                objectInfoList.others.push(objectInfo);
              }
            } else {
              const objectInfo = {
                x: centerX,
                y: centerY,
                type: object.type,
                color: object.fill,
              };
              objectInfoList.others.push(objectInfo);
            }
          });

          if (
            objectInfoList.images.length === 1 &&
            objectInfoList.others.length > 0
          ) {
            const blobData = new Blob([JSON.stringify(objectInfoList)], {
              type: "application/json",
            });
            const formData = new FormData();
            formData.append("data", blobData, "objectData.json");
            try {
              const result = await fetchSAMResult(formData);
              if (result && result.image_data) {
                const img = new Image();
                img.src = `data:image/png;base64,${result.image_data}`;

                img.onload = () => {
                  const canvasElement = document.createElement("canvas");
                  const ctx = canvasElement.getContext("2d");
                  canvasElement.width = img.width;
                  canvasElement.height = img.height;
                  ctx.drawImage(img, 0, 0);

                  // const newDataUrl = canvasElement.toDataURL("image/png");
                  // localStorage.setItem("activeObjectUrl", newDataUrl);

                  if (canvas) {
                    const fabricImage = new fabric.Image(img, {
                      left: 50,
                      top: 50,
                      selectable: true,
                    });

                    canvas.add(fabricImage);
                    canvas.renderAll();
                  }
                };
              }
            } catch (error) {
              console.error("SAM API 호출 오류:", error);
            }

            // fetch("http://localhost:8001/cv/sam", {
            //   method: "POST",
            //   body: formData,
            // })
            //   .then((response) => response.json())
            //   .then((result) => {
            //     const img = new Image();
            //     img.src = `data:image/png;base64,${result.image_data}`;
            //     img.onload = () => {
            //       const canvasElement = document.createElement("canvas");
            //       const ctx = canvasElement.getContext("2d");
            //       canvasElement.width = img.width;
            //       canvasElement.height = img.height;
            //       ctx.drawImage(img, 0, 0);

            //       const newDataUrl = canvasElement.toDataURL("image/png");
            //       localStorage.setItem("activeObjectUrl", newDataUrl);

            //       if (canvas) {
            //         const fabricImage = new fabric.Image(img, {
            //           left: 50,
            //           top: 50,
            //           selectable: true,
            //         });

            //         canvas.add(fabricImage);
            //         canvas.renderAll();
            //       }
            //     };
            //   })
            //   .then(() => console.log("Success"))
            //   .catch((error) => {
            //     console.error("Error:", error);
            //   });
          } else {
            console.log(
              "조건을 만족하지 않습니다: 이미지 1개, rect 또는 point가 1개 이상 필요"
            );
          }

          console.log("Selected Object Info:", objectInfoList);
        }
      });
    }

    canvas.on("selection:cleared", () => {
      console.log("Selection cleared");
    });
  };

  const handlePenTool = () => {
    canvas.freeDrawingBrush.color = brushColor; // 선택된 색상으로 설정
    canvas.freeDrawingBrush.width = brushWidth; // 선택된 두께로 설정
    canvas.isDrawingMode = true;
    canvas.defaultCursor = "crosshair";

    const createPoint = (x, y, info) => {
      const color = info ? "green" : "red"; // info 값에 따라 색상 결정
      const circle = new fabric.Circle({
        left: x,
        top: y,
        radius: 5,
        fill: color,
        selectable: true,
      });
      canvas.add(circle);
      canvas.renderAll();
    };

    canvas.on("mouse:down", (event) => {
      const pointer = canvas.getPointer(event.e); // 클릭한 위치의 좌표를 가져옵니다.
      const isAltPressed = event.e.altKey; // Alt 키가 눌렸는지 확인
      const isCtrlPressed = event.e.ctrlKey; // Ctrl 키가 눌렸는지 확인

      // Alt 또는 Ctrl이 눌렸을 때 드로잉 모드 비활성화
      if (isAltPressed || isCtrlPressed) {
        canvas.isDrawingMode = false; // 드로잉 모드 해제
        const info = isCtrlPressed; // Ctrl 눌렸으면 true, Alt 눌렸으면 false
        createPoint(pointer.x, pointer.y, info); // 포인트 생성
      } else {
        // 드로잉 모드 유지
        canvas.isDrawingMode = true;
      }
    });

    // 마우스 이동 이벤트에서 드로잉 모드 비활성화
    canvas.on("mouse:move", (event) => {
      const isAltPressed = event.e.altKey; // Alt 키가 눌렸는지 확인
      const isCtrlPressed = event.e.ctrlKey; // Ctrl 키가 눌렸는지 확인

      if (isAltPressed || isCtrlPressed) {
        canvas.isDrawingMode = false; // 드로잉 모드 해제
      } else {
        canvas.isDrawingMode = true; // 드로잉 모드 유지
      }
    });
  };

  const handleHandTool = () => {
    canvas.isDrawingMode = false;
    canvas.selection = false;
    canvas.defaultCursor = "grab";

    let panning = false;
    const handleMouseDown = () => {
      panning = true;
    };

    const handleMouseMove = (event) => {
      if (panning) {
        const delta = new fabric.Point(event.e.movementX, event.e.movementY);
        canvas.relativePan(delta);
      }
    };
    const handleMouseUp = () => {
      panning = false;
    };
    canvas.on("mouse:down", handleMouseDown);
    canvas.on("mouse:move", handleMouseMove);
    canvas.on("mouse:up", handleMouseUp);
  };

  const handleRectTool = () => {
    let rect;
    let isDrawing = false;
    canvas.isDrawingMode = false;

    const handleMouseDown = (event) => {
      isDrawing = true;
      canvas.selection = false;
      const pointer = canvas.getPointer(event.e);
      const startX = pointer.x;
      const startY = pointer.y;

      rect = new fabric.Rect({
        left: startX,
        top: startY,
        fill: "transparent", // fill color
        stroke: "black", // stroke color
        strokeWidth: 2, // stroke width
        selectable: false, // 비선택 가능
      });
      canvas.add(rect);
    };

    const handleMouseMove = (event) => {
      if (!isDrawing) return;
      const pointer = canvas.getPointer(event.e);
      rect.set({
        width: Math.abs(pointer.x - rect.left),
        height: Math.abs(pointer.y - rect.top),
        left: Math.min(pointer.x, rect.left),
        top: Math.min(pointer.y, rect.top),
      });
      canvas.renderAll();
    };

    const handleMouseUp = () => {
      isDrawing = false;
      canvas.selection = true;
      rect.selectable = true; // 최종적으로 선택 가능하도록 설정
      canvas.setActiveObject(rect);
      canvas.renderAll();
    };

    canvas.on("mouse:down", handleMouseDown);
    canvas.on("mouse:move", handleMouseMove);
    canvas.on("mouse:up", handleMouseUp);
  };

  const handleCrop = () => {
    // 현재 선택된 객체 가져오기
    const selectedObjects = canvas.getActiveObject();

    // 선택된 객체가 활성 선택인지 확인
    if (selectedObjects && selectedObjects.type === "activeSelection") {
      const objects = selectedObjects.getObjects();

      // 두 개의 객체가 선택되었는지 확인
      if (objects.length === 2) {
        const largeObject = objects[0]; // 큰 이미지 객체
        const smallObject = objects[1]; // 작은 이미지 객체

        // 큰 이미지와 작은 이미지의 경계 상자 가져오기
        const largeRect = largeObject.getBoundingRect();
        const smallRect = smallObject.getBoundingRect();

        // 겹치는 부분의 좌표 계산
        const x1 = Math.max(largeRect.left, smallRect.left);
        const y1 = Math.max(largeRect.top, smallRect.top);
        const x2 = Math.min(
          largeRect.left + largeRect.width,
          smallRect.left + smallRect.width
        );
        const y2 = Math.min(
          largeRect.top + largeRect.height,
          smallRect.top + smallRect.height
        );

        // 겹치는 부분이 있는지 확인
        if (x1 < x2 && y1 < y2) {
          // 크롭할 캔버스 생성
          const croppedCanvas = document.createElement("canvas");
          const cropWidth = x2 - x1; // 크롭할 너비
          const cropHeight = y2 - y1; // 크롭할 높이
          croppedCanvas.width = cropWidth;
          croppedCanvas.height = cropHeight;

          const ctx = croppedCanvas.getContext("2d");

          // 큰 이미지의 겹치는 부분만 그리기
          ctx.drawImage(
            largeObject._element, // 원본 큰 이미지
            x1 - largeRect.left, // 원본 이미지에서의 x 위치
            y1 - largeRect.top, // 원본 이미지에서의 y 위치
            cropWidth, // 크롭할 너비
            cropHeight, // 크롭할 높이
            0, // 크롭된 캔버스의 x 위치
            0, // 크롭된 캔버스의 y 위치
            cropWidth, // 크롭된 캔버스의 너비
            cropHeight // 크롭된 캔버스의 높이
          );

          // 크롭된 이미지를 fabric.Image로 변환하여 추가
          const croppedImage = new Image();
          croppedImage.src = croppedCanvas.toDataURL();
          croppedImage.onload = () => {
            const croppedFabricImage = new fabric.Image(croppedImage, {
              left: x1, // 새로운 이미지의 x 좌표
              top: y1, // 새로운 이미지의 y 좌표
              selectable: true,
            });

            // 새로운 객체로 캔버스에 추가
            canvas.add(croppedFabricImage);
            canvas.renderAll();
          };
        } else {
          alert("겹치는 부분이 없습니다.");
        }
      } else {
        alert("이미지와 사각형을 선택해야 합니다.");
      }
    } else {
      alert("이미지와 사각형을 선택해야 합니다.");
    }
  };

  const handlePolygonTool = () => {
    let isDrawing = false;
    const handleMouseDown = (event) => {
      canvas.isDrawingMode = false;
      const pointer = canvas.getPointer(event.e);
      setPolygonPoints((prev) => [...prev, { x: pointer.x, y: pointer.y }]);
      drawPolygon();
      isDrawing = true;
    };

    const handleMouseMove = (event) => {
      if (isDrawing) {
        drawPolygon();
      }
    };

    const handleMouseUp = () => {
      isDrawing = false;
    };

    canvas.on("mouse:down", handleMouseDown);
    canvas.on("mouse:move", handleMouseMove);
    canvas.on("mouse:up", handleMouseUp);
  };

  const drawPolygon = () => {
    // 임시 폴리곤 제거 (기존 코드와 동일)
    const tempPolygon = canvas
      .getObjects()
      .find((obj) => obj.type === "polygon" && obj.tempPolygon); // tempPolygon 속성으로 임시 폴리곤 식별
    if (tempPolygon) {
      canvas.remove(tempPolygon);
    }

    if (polygonPoints.length < 2) return; // 최소 두 점이 필요함

    const points = polygonPoints.map((p) => [p.x, p.y]);
    const polygon = new fabric.Polygon(polygonPoints, {
      fill: "transparent",
      stroke: "green",
      strokeWidth: 2,
      selectable: false,
      tempPolygon: true, // 임시 폴리곤 표시
    });

    canvas.add(polygon);
    canvas.renderAll();
  };

  const finalizePolygon = () => {
    // 임시 폴리곤 제거 (기존 코드와 동일)
    const tempPolygon = canvas
      .getObjects()
      .find((obj) => obj.type === "polygon" && obj.tempPolygon);
    if (tempPolygon) {
      canvas.remove(tempPolygon);
    }

    if (polygonPoints.length < 2) return; // 폴리곤이 완성되기 위해서는 두 점 이상이 필요합니다.

    const points = polygonPoints.map((p) => [p.x, p.y]);
    const finalizedPolygon = new fabric.Polygon(polygonPoints, {
      fill: "transparent",
      stroke: "black",
      strokeWidth: 2,
      selectable: true,
      tempPolygon: false, // 완료된 폴리곤 식별
    });

    canvas.add(finalizedPolygon);
    canvas.renderAll();

    // 폴리곤 점 초기화
    setPolygonPoints([]);
  };

  const deleteSelectedObjects = () => {
    setActiveTool("select");
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "activeSelection") {
        const objectsInSelection = activeObject.getObjects();
        objectsInSelection.forEach((obj) => {
          canvas.remove(obj); // 선택된 각 객체 삭제
        });
      } else {
        canvas.remove(activeObject); // 단일 객체 삭제
      }
      canvas.discardActiveObject(); // 선택 해제
      canvas.renderAll(); // 캔버스 다시 그리기
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const imgElement = new Image();
      imgElement.src = e.target.result;
      imgElement.onload = () => {
        const imgInstance = new fabric.Image(imgElement);
        imgInstance.set({
          left: 100, // 원하는 위치로 조정
          top: 100,
          angle: 0,
          opacity: 1,
        });
        canvas.add(imgInstance);
        canvas.renderAll();
      };
    };
    if (file) {
      reader.readAsDataURL(file); // 파일을 URL로 읽기
    }
  };

  /* Background 로 업로드 
const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
    const imgElement = new Image();
    imgElement.src = e.target.result;
    imgElement.onload = () => {
        // 배경 이미지로 설정
        canvas.setBackgroundImage(
        imgElement.src,
        canvas.renderAll.bind(canvas),
        {
            scaleX: canvas.width / imgElement.width,
            scaleY: canvas.height / imgElement.height,
            top: 0,
            left: 0,
        }
        );
    };
    };
    if (file) {
    reader.readAsDataURL(file);
    }
};
*/ const downloadImage = () => {
    const activeObjects = canvas.getActiveObjects(); // 여러 개의 선택된 객체 가져오기
    console.log(activeObjects);

    // 선택된 객체가 있을 경우
    if (activeObjects.length > 0) {
      // 선택된 객체의 경계 박스를 계산
      const boundingBox = activeObjects.reduce(
        (acc, obj) => {
          const objBounds = obj.getBoundingRect();
          acc.left = Math.min(acc.left, objBounds.left);
          acc.top = Math.min(acc.top, objBounds.top);
          acc.right = Math.max(acc.right, objBounds.left + objBounds.width);
          acc.bottom = Math.max(acc.bottom, objBounds.top + objBounds.height);
          return acc;
        },
        {
          left: Number.MAX_VALUE,
          top: Number.MAX_VALUE,
          right: Number.MIN_VALUE,
          bottom: Number.MIN_VALUE,
        }
      );

      // 경계 박스 크기 계산
      const boxWidth = boundingBox.right - boundingBox.left;
      const boxHeight = boundingBox.bottom - boundingBox.top;

      // 선택된 모든 객체를 복사해서 새로운 캔버스에 추가
      const tempCanvas = new fabric.Canvas(null, {
        width: boxWidth,
        height: boxHeight,
      });

      // 객체를 원래의 z-index 순서에 따라 정렬
      const sortedActiveObjects = activeObjects.sort((a, b) => {
        return canvas.getObjects().indexOf(a) - canvas.getObjects().indexOf(b);
      });

      const clonePromises = sortedActiveObjects.map((obj) => {
        return new Promise((resolve) => {
          console.log(obj);

          if (obj.type === "image") {
            // 이미지 객체의 경우
            fabric.Image.fromURL(obj._element.src, (clone) => {
              clone.set({
                left: obj.left - boundingBox.left, // 경계 박스 내의 상대적 위치로 조정
                top: obj.top - boundingBox.top, // 경계 박스 내의 상대적 위치로 조정
              });
              tempCanvas.add(clone);
              resolve();
            });
          } else {
            // 다른 객체의 경우
            obj.clone((clone) => {
              clone.set({
                left: obj.left - boundingBox.left, // 경계 박스 내의 상대적 위치로 조정
                top: obj.top - boundingBox.top, // 경계 박스 내의 상대적 위치로 조정
              });
              tempCanvas.add(clone);
              resolve();
            });
          }
        });
      });

      // 모든 객체가 클론된 후 이미지 추출
      Promise.all(clonePromises).then(() => {
        tempCanvas.renderAll(); // 캔버스 새로 고침
        const newDataURL = tempCanvas.toDataURL({
          format: "png",
          quality: 1.0,
        });

        // 이미지 다운로드 링크 생성하여 다운로드 실행
        const link = document.createElement("a");
        link.href = newDataURL;
        link.download = "selected-objects.png";
        link.click();
      });
    } else {
      // 선택된 객체가 없을 경우 전체 캔버스를 다운로드
      const dataURL = canvas.toDataURL({
        format: "png",
        quality: 1.0,
      });
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "canvas-image.png";
      link.click();
    }
  };

  const handleOCR = async () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject != null) {
      const dataUrl = activeObject.toDataURL();
      const response = await fetch(dataUrl);
      const blob = await response.blob();
      // FormData 생성
      const formData = new FormData();
      formData.append("file", blob, "canvas_image.png");

      const result = await fetchOCRResult(formData);
      console.log("result", result);
      if (result !== null && result.text) {
        const text = new window.fabric.Textbox(result.text, {
          left: 100,
          top: 100,
          width: 600,
          fontSize: 18,
          fill: "#000",
          editable: true,
        });
        canvas.add(text);
        canvas.renderAll();
      } else {
        alert("생성된 텍스트가 없습니다.");
      }
    }
  };

  const handleSD = async () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject != null) {
      const sd_tet_input = { prompt: activeObject.text };

      const result = await fetchSDResult(sd_tet_input);
      console.log("result", result);
      if (result !== null && result.images) {
        const img = new Image();
        img.src = `data:image/png;base64,${result.images}`;

        img.onload = () => {
          const canvasElement = document.createElement("canvas");
          const ctx = canvasElement.getContext("2d");
          canvasElement.width = img.width;
          canvasElement.height = img.height;
          ctx.drawImage(img, 0, 0);

          const newDataUrl = canvasElement.toDataURL("image/png");
          // localStorage.setItem("activeObjectUrl", newDataUrl);

          if (canvas) {
            const fabricImage = new fabric.Image(img, {
              left: 50,
              top: 50,
              selectable: true,
            });

            canvas.add(fabricImage);
            canvas.renderAll();
          }
        };
      } else {
        alert("생성된 텍스트가 없습니다.");
      }
    }
  };

  const handleSaveObject = async () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      const dataUrl = activeObject.toDataURL();
      // localStorage.setItem("activeObjectUrl", dataUrl);

      const response = await fetch(dataUrl);
      const blob = await response.blob();
      console.log(blob);
      // FormData 생성
      const formData = new FormData();
      formData.append("file", blob, "canvas_image.png");
      // const jsonObject = activeObject.toJSON();
      // localStorage.setItem("selectedObject", JSON.stringify(jsonObject));
      try {
        const apiResponse = await fetch("http://localhost:8001/ai/ocr", {
          method: "POST",
          body: formData,
        });
        if (apiResponse.ok) {
          const result = await apiResponse.json();
          console.log(result);
          if (result.text) {
            const text = new window.fabric.Textbox(result.text, {
              left: 100,
              top: 100,
              fontSize: 24,
              fill: "#000", // 텍스트 색상
              editable: true, // 텍스트 편집 가능 여부
            });
            canvas.add(text);
            canvas.renderAll();
          }
        } else {
          console.error("Error saving object:", apiResponse.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }

      //   if (apiResponse.ok) {
      //     const result = await apiResponse.json();
      //     console.log("Object saved successfully:", result);
      //     const img = new Image();
      //     img.src = `data:image/png;base64,${result.image_data}`;
      //     img.onload = () => {
      //       const canvas = document.createElement("canvas");
      //       const ctx = canvas.getContext("2d");
      //       canvas.width = img.width;
      //       canvas.height = img.height;
      //       ctx.drawImage(img, 0, 0);

      //       // activeObjectUrl로 저장
      //       const newDataUrl = canvas.toDataURL();
      //       localStorage.setItem("activeObjectUrl", newDataUrl);
      //     };
      //   } else {
      //     console.error("Error saving object:", apiResponse.statusText);
      //   }
      // } catch (error) {
      //   console.error("Error:", error);
      // }
      // activeObject.clone((clonedObject) => {
      //   // 복제된 객체를 캔버스에 추가
      //   clonedObject.set({
      //     left: activeObject.left + 20, // 원래 위치에서 조금 이동
      //     top: activeObject.top + 20,
      //   });
      //   canvas.add(clonedObject);
      //   canvas.renderAll();
      // });
    } else {
      console.log("선택된 객체가 없습니다.");
    }
  };

  return (
    <div className="canvas-container" ref={canvasContainerRef}>
      <canvas ref={canvasRef} />

      <div className="tool-bar">
        <button onClick={handleOCR}>OCR</button>
        <button
          onClick={() => setActiveTool("SAM")}
          disabled={activeTool === "SAM"}
        >
          SAM
        </button>
        <button onClick={handleSD}>SD</button>
        <button
          onClick={() => setActiveTool("select")}
          disabled={activeTool === "select"}
        >
          <FaMousePointer /> {/* 선택 도구 아이콘 */}
        </button>
        <button
          onClick={() => setActiveTool("pen")}
          disabled={activeTool === "pen"}
        >
          <FaPen /> {/* 펜 도구 아이콘 */}
        </button>

        <button
          onClick={() => setActiveTool("textbox")}
          disabled={activeTool === "textbox"}
        >
          <FaFont /> {/* 텍스트 도구 아이콘 */}
        </button>
        <button
          onClick={() => setActiveTool("hand")}
          disabled={activeTool === "hand"}
        >
          <FaHandRock /> {/* 핸드 도구 아이콘 */}
        </button>
        <button
          onClick={() => setActiveTool("rect")}
          disabled={activeTool === "rect"}
        >
          <FaSquare />
        </button>
        <button
          onClick={() => setActiveTool("polygon")}
          disabled={activeTool === "polygon"}
        >
          <FaStar /> {/* 폴리곤 버튼 추가 */}
        </button>
        <button
          onClick={deleteSelectedObjects} // 삭제 버튼 클릭 시 삭제 함수 호출
        >
          <FaTrash />
        </button>
        <button onClick={handleCrop}>
          <FaCut />
        </button>

        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: "none" }} // 기본 스타일 숨김
          id="image-upload"
        />
        <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
          <FaUpload /> {/* 업로드 아이콘 */}
        </label>
        <button onClick={downloadImage}>
          <FaDownload /> {/* 다운로드 아이콘 */}
        </button>
        {/* 색상 팔레트 */}
        <div className="color-palette">
          <input
            type="color"
            value={brushColor}
            onChange={(e) => setBrushColor(e.target.value)}
          />
        </div>
        {/* 선 두께 슬라이더 */}
        <div className="brush-width-slider">
          <input
            type="range"
            min="1"
            max="50"
            value={brushWidth}
            onChange={(e) => {
              setBrushWidth(e.target.value);
              if (canvas && canvas.isDrawingMode) {
                canvas.freeDrawingBrush.width = e.target.value; // 슬라이더 값으로 두께 설정
              }
            }}
          />
          <span>{brushWidth}px</span>
        </div>
      </div>
    </div>
  );
};

export default HandwritingCanvas;
