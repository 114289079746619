import React, { useEffect, useState } from "react";
import ViewPort from "../components/Image/ViewPort";
import ViewPortBackground from "../components/Three/ViewPortBackground";
import DynamicTable from "../components/DynamicTable";

const Home = () => {
  const imagePaths = [];
  for (let i = 1; i <= 7; i++) {
    imagePaths.push(`/images/MainViewPort/image${i}.jpg`);
  }

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSmallScreen = viewportWidth < 1000;

  const recentPosts = [
    { id: 1, title: "첫 번째 게시물", date: "2024-10-01" },
    { id: 2, title: "두 번째 게시물", date: "2024-10-02" },
    { id: 3, title: "세 번째 게시물", date: "2024-10-03" },
  ];
  const recentPostsColumns = [{ 제목: "title", 날짜: "date" }];
  const usefulSites = [
    { id: 1, name: "무료 사진", url: "https://unsplash.com/ko" },
    { id: 2, name: "무료 이미지", url: "https://www.pngegg.com" },
    { id: 3, name: "강의 사이트", url: "https://www.inflearn.com" },
    { id: 4, name: "강의 사이트", url: "https://www.udemy.com/ko" },
    {
      id: 5,
      name: "3D web 예제",
      url: "https://r3f.docs.pmnd.rs/getting-started/examples",
    },
    {
      id: 6,
      name: "정부 UI/UX 가이드라인",
      url: "https://uiux.egovframe.go.kr/guide/index.html",
    },
    { id: 7, name: "전자책", url: "https://wikidocs.net/" },
    { id: 8, name: "LLM", url: "https://www.perplexity.ai/" },
    { id: 8, name: "데이터산업진흥원", url: "https://www.kdata.or.kr/" },
    { id: 8, name: "AI허브", url: "https://aihub.or.kr/" },
  ];
  const usefulSitesColumns = [{ 제목: "name", 링크: "url" }];

  return (
    <>
      <div className="home-wrap">
        <div className="home-viewport">
          <ViewPort
            style={{ width: isSmallScreen ? "100%" : "40%", height: "30vh" }}
            imagePaths={imagePaths}
            autoplay={true}
            autoplaySpeed={2000}
          />
          <div className="home-viewport-text">
            <h2>관심사항</h2>
            <p>기상 : 해양/기상 예측, 대시보드, GIS 시각화에 관심이 있어요!</p>
            <p>주식 : 주식 관련 종목 추천, 대시보드 생성에 관심이 있어요!</p>
            <p>
              운동 : AI와 운동을 연계한 프로젝트에 관심이 있어요!(관절인식 등)
            </p>
            <p>AI : Vision 관련 AI에 주로 관심이 있어요!</p>
            <ViewPortBackground />
          </div>
        </div>
        <div className="home-recent-contents">
          <div>
            <h2>최근 게시물</h2>
            <DynamicTable data={recentPosts} columns={recentPostsColumns} />
          </div>

          <div>
            <h2>유용한 사이트</h2>
            <DynamicTable data={usefulSites} columns={usefulSitesColumns} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
