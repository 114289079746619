import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import URL from "../../constants/url";
import Dropdown from "../Dropdown";
// import HeaderWithTablist from "./HeaderWithTablist";
import Navbar from "./Navbar";
const Header = ({ isLoggedIn, setIsLoggedIn, activeItem, setActiveItem }) => {
  const menuItems = [
    {
      title: "소개",
      subLinks: [
        { label: "비전", href: URL.VISION },
        { label: "로드맵", href: URL.LOADMAP },
        { label: "About Me", href: URL.ABOUTME },
      ],
    },
    {
      title: "대시보드",
      subLinks: [
        { label: "주식", href: URL.STOCK },
        { label: "기상", href: URL.WEATHER },
      ],
    },
    { title: "게시글", link: URL.POST }, // 서브 링크 없음
    { title: "자료실", link: URL.RES }, // 서브 링크 없음
    { title: "커뮤니티", link: URL.QA }, // 링크 추가
    {
      title: "실험적 기능",
      subLinks: [
        { label: "ONNX.js", href: URL.ONNX },
        { label: "WEBCAM", href: URL.WEBCAM },
        { label: "TODO", href: URL.TODO },
      ],
    },
  ];
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    setIsLoggedIn(false);
  };

  return (
    <>
      <header>
        <div className="header-main">
          <Link to={URL.HOME} className="logo">
            <img
              src="/images/logo/logo.png"
              alt="IT 기술공부를 위한 웹사이트"
              width={110}
              height={"auto"}
            />
          </Link>
          <nav className="header-main-nav">
            <div className="header-utils-wrap">
              <div id="header-utils">
                <ul className="list-wrap">
                  <Dropdown
                    label="강의목록"
                    options={[
                      {
                        label: "Network",
                        link: "https://www.inflearn.com/course/네트워크-비전공자-이야기",
                      },
                      {
                        label: "Docker",
                        link: "https://www.inflearn.com/course/빠른-사용-도커-이야기",
                      },
                      {
                        label: "Dashboard(R)",
                        link: "https://wikidocs.net/book/7701",
                      },
                    ]}
                  />
                  <Dropdown
                    label="유용한기능"
                    options={[
                      {
                        label: "Gitlab",
                        link: "http://gitlab.ducj.duckdns.org",
                      },
                      {
                        label: "PM tool",
                        link: "http://project.ducj.duckdns.org",
                      },
                    ]}
                  />
                </ul>
              </div>
              <ul className="header-item">
                <li>
                  <NavLink
                    to={URL.SEARCH}
                    className={({ isActive }) => (isActive ? "cur" : "")}
                  >
                    <div>
                      <button className="search" />
                      <p>통합검색</p>
                    </div>
                  </NavLink>
                </li>
                {isLoggedIn ? (
                  <li>
                    <NavLink
                      to={URL.MAIN}
                      className={({ isActive }) => (isActive ? "cur" : "")}
                      onClick={handleLogout}
                    >
                      <div>
                        <button className="logout" />
                        <p>로그아웃</p>
                      </div>
                    </NavLink>
                  </li>
                ) : (
                  <>
                    <li>
                      <NavLink
                        to={URL.LOGIN}
                        className={({ isActive }) => (isActive ? "cur" : "")}
                      >
                        <div>
                          <button className="login" />
                          <p>로그인</p>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={URL.CRT_ACNT}
                        className={({ isActive }) => (isActive ? "cur" : "")}
                      >
                        <div>
                          <button className="crt_acnt" />
                          <p>회원가입</p>
                        </div>
                      </NavLink>
                    </li>
                  </>
                )}
                <li>
                  <NavLink
                    to={URL.PERSONAL_INFO}
                    className={({ isActive }) => (isActive ? "cur" : "")}
                  >
                    <div>
                      <button className="my_info" />
                      <p>개인메뉴</p>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <Navbar
          items={menuItems}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
        {/* <HeaderWithTablist /> */}
      </header>
    </>
  );
};
export default Header;
