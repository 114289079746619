import axios from "axios";
import URL from "../constants/url";

export const UploadData = async (fromData) => {
  try {
    const response = await axios.post(
      `${URL.NODE_API}/image/upload-image`,
      fromData
    );
    return response;
  } catch (error) {
    console.error("Failed Upload", error);
    throw error;
  }
};
