import React, { useEffect, useRef } from "react";

const ArrayToDiv = ({ imageData }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (imageData && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // canvas 크기를 imageData의 크기로 설정
      canvas.width = imageData.width;
      canvas.height = imageData.height;

      // imageData를 canvas에 그리기
      ctx.putImageData(imageData, 0, 0);
    }
  }, [imageData]); // imageData가 변경될 때마다 effect 실행

  return (
    <div>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default ArrayToDiv;
