import ViewPort from "../../components/Image/ViewPort";
import PDFViewer from "../../components/PDFViewer";

const AboutMe = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const imagePaths = [];
  for (let i = 1; i <= 14; i++) {
    imagePaths.push(`/images/portfolio/page${i}.png`);
  }

  return (
    <>
      <div>
        <div className="subtitle" style={{ marginLeft: "30px" }}>
          포트폴리오
        </div>
        <ViewPort
          style={{ width: "60vw", height: "100%" }}
          imagePaths={imagePaths}
        />
      </div>
      <div>
        <div
          className="subtitle"
          style={{ marginLeft: "30px", marginBottom: "20px" }}
        >
          자기소개서
        </div>
        <div
          style={{
            marginLeft: "30px",
            height: "100vh",
            width: "80vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isMobile ? (
            <PDFViewer fileUrl={"/images/portfolio/자기소개서.pdf"} />
          ) : (
            <iframe
              src={"/images/portfolio/자기소개서.pdf"}
              title={"자기소개서"}
              width="100%"
              height="100%"
              style={{ border: "1px solid black" }}
            />
          )}
        </div>
      </div>
      <div>
        <div
          className="subtitle"
          style={{ marginLeft: "30px", marginBottom: "20vh" }}
        >
          경력기술서
        </div>
      </div>
    </>
  );
};
export default AboutMe;
