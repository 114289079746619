/*
    https://uiux.egovframe.go.kr/guide/component/component_02_02.html
    About, IT 개념, Contents, Book, Language, 강의, 표준화 문서, 대시보드
    자격증 정리
*/
const URL = {
  DOMAIN: "https://blog.cjcho.site/",
  HOME: "/",

  // intro
  VISION: "/intro/vision",
  LOADMAP: "/intro/loadmap",
  ABOUTME: "/intro/me",

  // dash
  DASH: "/dash",
  STOCK: "/dash/stock",
  WEATHER: "/dash/weather",
  LABELING: "/dash/labeling",

  // post
  POST: "/post",
  POST_WRITE_BASE: "/post/write",
  POST_WRITE: "/post/write/:link",
  POST_READ: "/post/read/:link",

  RES: "/resource",

  QA: "/QA",
  CRT_ACNT: "/create_account",

  // experiment
  ONNX: "/experiment/onnx",
  TODO: "/experiment/todo",
  WEBCAM: "/experiment/cam",

  CRTF: "/personal/crtf",
  LOGIN: "/personal/login",
  PERSONAL_INFO: "/personal/info",

  FORM: "/form",
  SEARCH: "/search",
  TEST: "/test",
  ADMIN: "/admin",

  //  API
  CV_API: "/cv",
  SD_API: "/sd",
  GPT_API: "/openai",
  NODE_API: "/node",

  // SD_API: "http://ducj.iptime.org:8001", // http://ducj.iptime.org:8001 /sd
  // GPT_API: "http://localhost:8000", // http://localhost:8000 /openai
  // NODE_API: "http://localhost:3001", //http://localhost:3001, /node
};

export default URL;
