import axios from "axios";
import URL from "../constants/url";

export const getGptData = async (data) => {
  console.log(`${URL.GPT_API}/chat`, data);
  try {
    const response = await axios.post(`${URL.GPT_API}/chat`, data);
    console.log(response);
    return response.data.result;
  } catch (error) {
    console.error("Failed to fetch GPT data", error);
    throw error;
  }
};
export const getGptPostData = async (data) => {
  try {
    const response = await axios.post(`${URL.GPT_API}/post`, data);
    return response.data.result;
  } catch (error) {
    console.error("Failed to fetch GPT data", error);
    throw error;
  }
};
