import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchProtectedData, handleRefreshToken } from "../../../api/auth";
import { CheckedLogin } from "../../../api/UserAPI";

const Login = ({ isLogin, setIsLogin, setIsAdmin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (isLogin) {
      // console.log("login Status", isLogin);
      navigate("/");
    }
  }, [isLogin, navigate]);

  const handleLogin = async ({ username, password }) => {
    CheckedLogin(
      { username: username, password: password },
      setIsLogin,
      setIsAdmin
    );
    // try {
    //   const response = await axios.post("http://localhost:3001/login", {
    //     username,
    //     password,
    //   });
    //   localStorage.setItem("accessToken", response.data.accessToken);
    //   localStorage.setItem("refreshToken", response.data.refreshToken);
    //   setIsLogin(true);
    //   navigate("/");
    // } catch (error) {
    //   console.error("Login failed:", error.response);
    // }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Email:", email);
    // console.log("Password:", password);
    handleLogin({ username: email, password: password });
    // 여기에 로그인 로직을 추가할 수 있습니다.
  };

  return (
    <div
      style={{
        maxWidth: "400px",
        margin: "auto",
        marginTop: "50px",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "5px",
      }}
    >
      <h2>로그인</h2>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            marginBottom: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor="email">이메일:</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ width: "inherbit", padding: "8px", marginTop: "5px" }}
          />
        </div>
        <div
          style={{
            marginBottom: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor="password">비밀번호:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{ width: "inherbit", padding: "8px", marginTop: "5px" }}
          />
        </div>
        <button
          type="submit"
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            marginBottom: "2px",
          }}
        >
          로그인
        </button>
        <button
          type="button"
          onClick={() => navigate("/create_account")}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
          }}
        >
          회원가입
        </button>
        <button
          onClick={fetchProtectedData}
          disabled={!localStorage.getItem("accessToken")}
        >
          토큰 테스트
        </button>
        <button
          onClick={handleRefreshToken}
          disabled={!localStorage.getItem("accessToken")}
        >
          리프레시 토큰 테스트
        </button>
      </form>
    </div>
  );
};

export default Login;
