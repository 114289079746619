import { StrictMode } from "react";
import RootRoutes from "./routes";

/* css */
import "./css/base.css";
import "./css/component.css";
import "./css/layout.css";
import "./css/page.css";

function App() {
  return (
    <StrictMode>
      <RootRoutes />
    </StrictMode>
  );
}

export default App;
