import React, { useState } from "react";

const InfoEdit = () => {
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // 서버에 수정된 정보 전송
    console.log("수정된 정보:", userInfo);
    // 예: axios.post('/api/update', userInfo);
  };

  return (
    <div className="personal-info-container">
      <h2>개인 정보 수정</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>이름:</label>
          <input
            type="text"
            name="name"
            placeholder="홍길동"
            value={userInfo.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>이메일:</label>
          <input
            type="email"
            name="email"
            placeholder="email@domain.com"
            value={userInfo.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>전화번호:</label>
          <input
            type="tel"
            name="phone"
            placeholder="010-1234-4567"
            value={userInfo.phone}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">저장</button>
      </form>
    </div>
  );
};

export default InfoEdit;
