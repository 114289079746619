import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ScaleControl,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
const defaultIcon = new L.Icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  shadowSize: [41, 41],
});
const LeafletMap = () => {
  // OpenStreetMap 으로 지도 표시 , 지도 마커와 팝업 추가, 창 크기 변경에 반응
  const [tilesLoaded, setTilesLoaded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      window.dispatchEvent(new Event("resize"));
    };
    const onLoad = () => {
      setTilesLoaded(true);
    };

    // 창 크기 조정 이벤트 추가
    window.addEventListener("resize", handleResize);

    // 맵 요소를 선택하고 로드 이벤트 추가
    const map = document.querySelector(".leaflet-map-container");
    if (map) {
      map.addEventListener("load", onLoad);
    }

    // 컴포넌트 언마운트 시 이벤트 제거
    return () => {
      window.removeEventListener("resize", handleResize);
      if (map) {
        map.removeEventListener("load", onLoad);
      }
    };
  }, []);

  const position = [37.394346, 126.96388];

  return (
    <MapContainer
      className="leaflet-wrap"
      center={position}
      zoom={10}
      minZoom={7}
      zoomControl={true}
      attributionControl={true}
    >
      <TileLayer
        onLoad={() => {
          console.log("Tiles loaded");
        }}
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ScaleControl position="bottomleft" />
      <Marker position={position} icon={defaultIcon}>
        <Popup>
          <a
            href="https://map.naver.com/p/directions/-/14133556.485621,4494216.936457,%ED%8F%89%EC%B4%8C%EC%97%AD%204%ED%98%B8%EC%84%A0,441,SUBWAY_STATION/-/transit?c=18.00,0,0,0,dh"
            target="_blank"
            rel="noopener noreferrer"
          >
            대한민국, 경기도, 안양시 동안구 부림로 123
          </a>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default LeafletMap;
