const LoadMap = () => {
  return (
    <>
      <div className="loadmap-container">
        <div className="loadmap-contents">
          <div>
            <div className="subtitle">로드맵</div>
            <div className="vision-wrap">
              <h2 style={{ color: "#999999" }}>
                보다 효율적이고 직관적인 IT 공부를 위한
              </h2>
              <h2 style={{ fontWeight: 800, fontSize: "2.5rem" }}>
                <span className="highlight">IT</span> 지식 공유 사이트
              </h2>

              <p className="vision-text">
                IT 기술을 배우고자 하는 사람이 전공 유무에 관계없이 직관적으로
                배울 수 있는 곳을 만들고자 합니다.
              </p>
              <div className="keyword-container">
                <span className="keyword-circle c1">책/강의</span>
                <span className="keyword-circle c3">게시글</span>
                <span className="keyword-circle c5">실습</span>
              </div>
            </div>
          </div>
          <div>
            <div className="subtitle">0단계: 포스팅 플랫폼 개발</div>
            <p className="paragraph">
              <span className="highlight">초기 기능구현</span>: 관리자 페이지 및
              회원가입 구현.
              <br />
              <span className="highlight">포스팅 마인드맵 시각화</span>: 포스팅
              결과 시각화.
              <br />
              <span className="highlight">플랫폼 디자인</span>: 사용자 친화적인
              UI/UX 디자인 설계.
            </p>

            <div className="keyword-container">
              <span className="keyword-circle c2">정보전달</span>
              <span className="keyword-circle c4">마인드맵</span>
            </div>
          </div>
          <div>
            <div className="subtitle">1단계: 포스팅 작성 (1-3개월)</div>
            <p className="paragraph">
              <span className="highlight">IT 기술 기초 과정</span>: 프로그래밍,
              데이터베이스 기초적인 IT 기술 강의 개발.
              <br />
              <span className="highlight">기술 자료 작성</span>: 각 기술의 개념,
              역사, 활용 사례 등을 포함한 자료 준비.
              <br />
              <span className="highlight">플랫폼 디자인</span>: 사용자 친화적인
              UI/UX 디자인 설계.
            </p>
            <div className="keyword-container">
              <span className="keyword-circle c1">체계적</span>
              <span className="keyword-circle c3">계층적</span>
              <span className="keyword-circle c5">가시적</span>
            </div>
          </div>

          <div>
            <div className="subtitle">2단계: 대시보드 개발 (4-6개월)</div>
            <p className="paragraph">
              <span className="highlight">대시보드 구축</span>: 주식 시장, 기상
              예측 등의 실시간 데이터를 시각화할 수 있는 대시보드 개발.
              <br />
              <span className="highlight">데이터 소스 통합</span>: API를 통해
              외부 데이터 소스를 연동하여 실시간 데이터 수집.
              <br />
              <span className="highlight">사용자 시나리오 제작</span>: 대시보드
              활용에 대한 교육 시나리오와 실습 예제 작성.
            </p>
            <div className="keyword-container">
              <span className="keyword-circle c2">간단</span>
              <span className="keyword-circle c4">명확</span>
            </div>
          </div>
          <div>
            <div className="subtitle">3단계: 레이블링 툴 개발 (7-9개월)</div>
            <p className="paragraph">
              <span className="highlight">레이블링 도구 개발</span>: 데이터셋에
              대한 레이블링을 지원하는 도구 설계 및 구현.
              <br />
              <span className="highlight">실습 환경 제공</span>: 사용자가 직접
              데이터셋을 레이블링하고, 결과를 분석할 수 있는 환경 마련.
              <br />
              <span className="highlight">피드백 시스템 구축</span>: 사용자의
              레이블링 결과에 대한 피드백 기능 추가.
            </p>
            <div className="keyword-container">
              <span className="keyword-circle c1">효율성</span>
              <span className="keyword-circle c3">연계성</span>
              <span className="keyword-circle c5">활용성</span>
            </div>
          </div>
          <div>
            <div className="subtitle">
              4단계: 지속적인 개선 및 확장 (10-12개월)
            </div>
            <p className="paragraph">
              <span className="highlight">사용자 피드백 반영</span>: 사용자
              경험을 지속적으로 개선하기 위한 피드백 수집 및 반영.
              <br />
              <span className="highlight">신규 콘텐츠 추가</span>: 최신 IT 기술
              및 트렌드에 맞춘 교육 콘텐츠 추가.
              <br />
              <span className="highlight">커뮤니티 구축</span>: 사용자 간의
              네트워킹 및 경험 공유를 위한 커뮤니티 플랫폼 개발.
            </p>
            <div className="keyword-container">
              <span className="keyword-circle c2">수용</span>
              <span className="keyword-circle c4">확장</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoadMap;
