import React, { useState, useEffect } from "react";

import FileUploader from "../../components/Image/Uploader/FileUploader";
import { getGptPostData } from "../../api/PyAPI";
import { UploadData } from "../../api/UploadAPI";
import { getTitleData } from "../../api/PostAPI";
const PostWriteSettings = ({
  select1,
  setSelect1,
  setGptResult,
  fileName,
  setFileName,
  tags,
  setTags,
  source,
  setSource,
}) => {
  const [textAreaValue, setTextAreaValue] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [input, setInput] = useState("");
  const [titleList, setTitleList] = useState([]);

  const handleGenerate = async () => {
    if (apiKey !== "" && textAreaValue !== "") {
      const gpt_message = await getGptPostData({
        key: apiKey,
        title: textAreaValue,
      });
      if (gpt_message) {
        setGptResult(gpt_message);
      }
    } else {
      alert("키와 타이틀을 입력해주세요.");
    }
  };
  useEffect(() => {
    // console.log("filename", fileName);
    if (fileName && typeof filename !== "string") {
      if (fileName.data) {
        setFileName(fileName.data["files"]);
      }
    }
  }, [fileName, setFileName]);

  useEffect(() => {
    const fetchTitleData = async () => {
      try {
        const titles = await getTitleData();
        setTitleList(titles);
      } catch (error) {
        console.error("Failed to fetch titles:", error);
      }
    };

    fetchTitleData();
  }, []);

  // Tag
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && input.trim() !== "") {
      setTags([...tags, input.trim()]);
      setInput("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleSelectChange = (event) => {
    setSource(event.target.value);
  };

  return (
    <div
      style={{
        position: "fixed",
        // marginTop: headerHeight + "80px",
        margin: "0px",
        padding: "20px",
        // paddingTop: "80px",
        backgroundColor: "#fafafa",
        height: "100%",
        width: "400px",
        right: "0",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        border: "1px solid lightgray",
        zIndex: "2",
      }}
    >
      <h2 style={{ margin: "0px" }}>게시글 설정</h2>
      <div
        style={{
          paddingLeft: "20px",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        <h3>공개 여부</h3>
        <select
          style={{ width: "80%", height: "30px" }}
          value={select1}
          onChange={(e) => setSelect1(e.target.value)}
        >
          <option value="public">공개</option>
          <option value="private">비공개</option>
        </select>

        <h3>자동 생성</h3>
        <input
          type="password"
          placeholder="GPT API 키를 입력해주세요."
          style={{ width: "80%" }}
          value={apiKey} // API 키 상태로 설정
          onChange={(e) => setApiKey(e.target.value)} // API 키 변경 시 상태 업데이트
        />
        <textarea
          value={textAreaValue}
          onChange={(e) => setTextAreaValue(e.target.value)} // textarea 값 변경 시 상태 업데이트
          rows="4"
          cols="30"
          placeholder="원하는 내용을 입력해주세요. 결과는 포스팅란에 바로 기입됩니다."
          style={{ width: "80%", resize: "none" }} // 크기 조절 방지
        />
        <div
          style={{
            display: "inline-block", // 인라인 블록으로 설정
            width: "80%",
            backgroundColor: "#007bff", // 배경색
            color: "white", // 텍스트 색상
            border: "none", // 테두리 없애기
            borderRadius: "4px", // 둥근 모서리
            cursor: "pointer", // 마우스 커서 스타일
            textAlign: "center", // 텍스트 가운데 정렬
            transition: "background-color 0.3s", // 배경색 전환 효과
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = "#0056b3")
          } // 마우스 오버 시 색상 변경
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = "#007bff")
          } // 마우스 아웃 시 원래 색상으로
          onClick={handleGenerate}
        >
          자동 생성 버튼
        </div>

        <div>
          <h2>태그</h2>
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="태그를 입력하고 Enter 키를 누르세요"
            style={{
              width: "80%",
            }}
          />
          <div style={{ marginTop: "10px" }}>
            {tags.map((tag, index) => (
              <span
                key={index}
                style={{
                  padding: "5px",
                  background: "#e0e0e0",
                  borderRadius: "5px",
                  margin: "5px",
                }}
              >
                {tag}
                <button
                  onClick={() => handleRemoveTag(tag)}
                  style={{ marginLeft: "5px" }}
                >
                  x
                </button>
              </span>
            ))}
          </div>
        </div>
        <div>
          <h2>미리보기</h2>
          <FileUploader
            direction="column"
            apiUploadFunction={UploadData}
            uploadFiles={fileName}
            setUploadFiles={setFileName}
          />
        </div>
        <div>
          <h2>MindMap Setting</h2>
          <select
            id="title-select"
            value={source}
            onChange={handleSelectChange}
          >
            <option value="">-- Select a title --</option>
            {titleList.map((item, index) => (
              <option key={index} value={item.title}>
                {item.title}
              </option> // item.title로 수정
            ))}
          </select>

          <p>Selected Target: {source}</p>
        </div>
      </div>
    </div>
  );
};
export default PostWriteSettings;
