import React, { useState } from "react";
import CryptoJS from "crypto-js";
import InputField from "../../../components/acnt/InputField";
import { CreateUser } from "../../../api/UserAPI";

const CrtAcnt = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateInput = () => {
    // 기본 에러 메시지 초기화
    setErrorMessage("");

    // ID 유효성 검사 (영문 3자 이상)
    if (userId.length < 3) {
      setErrorMessage("ID는 3자 이상이어야 합니다.");
      return false;
    }

    // 비밀번호 유효성 검사 (영문 7자 이상)
    if (password.length < 7) {
      setErrorMessage("비밀번호는 7자 이상이어야 합니다.");
      return false;
    }

    // 비밀번호 일치 여부 확인
    if (password !== confirmPassword) {
      setErrorMessage("비밀번호가 일치하지 않습니다.");
      return false;
    }

    // 이름 유효성 검사 (빈 값 체크)
    if (!name.trim()) {
      setErrorMessage("이름을 입력하세요.");
      return false;
    }

    // 전화번호 유효성 검사 (형식 체크)
    const phonePattern = /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/; // "010-1234-5678" 형식
    if (!phonePattern.test(phone)) {
      setErrorMessage("전화번호 형식이 올바르지 않습니다. (예: 010-1234-5678)");
      return false;
    }

    // 이메일 유효성 검사
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setErrorMessage("이메일 형식이 올바르지 않습니다.");
      return false;
    }

    return true; // 모든 검증 통과 시 true 반환
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // 유효성 검사
    if (!validateInput()) {
      console.log(errorMessage); // 현재의 오류 메시지를 콘솔에 출력
      return; // 유효성 검사 실패 시 종료
    }

    // 패스워드 암호화
    const encryptedPassword = CryptoJS.AES.encrypt(
      password,
      "secret-key"
    ).toString();

    const userData = {
      username: userId,
      password: password,
      name: name,
      phone: phone,
      email: email,
    };

    CreateUser(userData);
  };
  console.log(userId);
  return (
    <div className="acnt-container">
      <div className="subtitle" style={{ backgroundColor: "transparent" }}>
        회원가입
      </div>
      <form className="crt-acnt-form" onSubmit={handleSubmit}>
        <InputField
          label="ID"
          type="text"
          placeholder="영문 3자 이상"
          value={userId}
          onChange={setUserId}
          minLength={3}
          required
        />
        <InputField
          label="Password"
          type="password"
          placeholder="영문 7자 이상"
          value={password}
          onChange={setPassword}
          minLength={7}
          required
        />
        <InputField
          label="Confirm Password"
          type="password"
          placeholder="영문 7자 이상"
          value={confirmPassword}
          onChange={setConfirmPassword}
          minLength={7}
          required
        />
        <InputField
          label="Name"
          type="text"
          value={name}
          placeholder="홍길동"
          onChange={setName}
          minLength={2}
          required
        />
        <InputField
          label="Phone"
          type="tel"
          placeholder="010-1234-5678"
          value={phone}
          pattern="[0-9]*" // 숫자만 입력받도록 설정
          maxLength="13" // 전화번호의 최대 길이
          onChange={setPhone}
          required
        />
        <InputField
          label="Email"
          type="text"
          value={email}
          onChange={setEmail}
          placeholder="email@domain.com"
          minLength={7}
          required
        />
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <button type="submit">회원가입</button>
      </form>
    </div>
  );
};

export default CrtAcnt;
