import React from "react";

const PaintBoxButton = ({ onClick }) => {
  return (
    <button className="paint-box-button" onClick={onClick}>
      🎨
    </button>
  );
};

export default PaintBoxButton;
