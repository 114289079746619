import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

const Navbar = ({ items, activeItem, setActiveItem }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);

  const handleItemClick = (item) => {
    if (item.link) {
      navigate(item.link);
    } else {
      setActiveItem((prevItem) =>
        prevItem === item.title ? null : item.title
      );
    }
  };

  const renderSubLinks = () => {
    const activeLinks = items.find(
      (item) => item.title === activeItem
    )?.subLinks;

    if (!activeLinks) return null;

    return (
      <div className="manu-nav-list">
        <div>
          {activeLinks.map((link, index) => (
            <NavLink
              key={index}
              to={link.href}
              onClick={() => setActiveItem(null)}
            >
              {link.label}
            </NavLink>
          ))}
        </div>
      </div>
    );
  };

  const handleScroll = (event) => {
    const activeLinks = items.find(
      (item) => item.title === activeItem
    )?.subLinks;

    if (activeLinks && activeLinks.length > 0) {
      setIsVisible(true);
    } else {
      if (event.deltaY > 0) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [activeItem, items]);

  return (
    <>
      <nav className={`manu-nav ${isVisible ? "visible" : "hidden"}`}>
        <ul>
          {items.map((item) => (
            <li
              key={item.title}
              onClick={() => handleItemClick(item)}
              style={{
                borderBottom:
                  activeItem === item.title ? "2px solid #007bff" : "none",
              }}
            >
              {item.title}
            </li>
          ))}
        </ul>
      </nav>
      {activeItem && renderSubLinks()}
    </>
  );
};

export default Navbar;
