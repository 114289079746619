import axios from "axios";
import URL from "../constants/url";
export const fetchProtectedData = async () => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log(accessToken);
  try {
    const response = await axios.get(`${URL.NODE_API}/user/token-check`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    // console.error("Fetching protected data failed:", error.response?.data);
    if (error.response?.status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      //   navigate("/personal/login"); // 로그인 페이지로 이동
      //   alert("Session expired. Please log in again.");
    } else {
      alert(
        "An error occurred while fetching protected data. Please try again later."
      );
    }
    return null;
  }
};

export const handleRefreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    console.error("No refresh token available");
  }
  try {
    const response = await axios.post(`${URL.NODE_API}/user/token-refresh`, {
      refreshToken,
    });
    localStorage.setItem("accessToken", response.data.accessToken);
  } catch (error) {
    console.error("Refresh token failed:", error.response.data);
    localStorage.removeItem("refreshToken");
    alert("Please log in again.");
  }
};
