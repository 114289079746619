const Todo = () => {
  return (
    <>
      <div style={{ margin: "30px" }}>
        <div>
          <h2>AI 기능</h2>
          <p>그림판에 Stable Diffusion 추가</p>
          <p>Control Net 추가</p>
          <p>2D image to 3D(TripoSR) 기능 추가</p>
          <p>Super Resolution 기능 추가</p>
        </div>
        <div>
          <h2>웹 기능</h2>
          <p>3D object Three.js로 볼 수 있게 추가 </p>
        </div>
      </div>
    </>
  );
};
export default Todo;
