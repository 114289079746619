import React from "react";
import { useNavigate } from "react-router-dom";
import { Graph } from "react-d3-graph";
import MaindMapConfig from "./MaindMapConfig";
// import data from "./MindMapData";

const MindMap = ({ data = { nodes: [], links: [] } }) => {
  const navigate = useNavigate();
  // https://codesandbox.io/p/sandbox/react-d3-graph-demo-e8d2pp?file=%2Fsrc%2Fdata.js%3A1%2C4264

  // the graph configuration, just override the ones you need

  const onClickNode = (nodeId) => {
    // 클릭한 노드의 ID를 콘솔에 출력
    const nodeurl = encodeURIComponent(nodeId);
    // console.log(`Clicked node: ${nodeId}`);
    // 추가적으로 원하는 동작을 여기에 구현
    // window.alert(`Clicked node: ${nodeId}`);
    navigate(`/post/read/${nodeurl}`);
  };

  const onClickLink = (source, target) => {
    // 클릭한 링크의 출발점과 도착점을 콘솔에 출력
    console.log(`Clicked link between: ${source} and ${target}`);
    // 추가적으로 원하는 동작을 여기에 구현
    window.alert(`Clicked link between: ${source} and ${target}`);
  };
  return (
    <div className="mindmap-wrap">
      {data && data.nodes.length > 0 ? (
        <Graph
          id="graph-id" // id is mandatory
          data={data}
          config={MaindMapConfig}
          onClickNode={onClickNode}
          // onClickLink={onClickLink}
        />
      ) : (
        <div>Loading...</div> // 데이터가 로드되지 않았을 때 표시할 로딩 메시지
      )}
    </div>
  );
};
export default MindMap;
