import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function ViewPort({
  imagePaths,
  style = {},
  autoplay = false,
  autoplaySpeed = 3000,
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowBackIcon />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowForwardIcon />
      </div>
    );
  };

  const settings = {
    dots: !isMobile,
    draggable: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    waitForAnimate: false,
    centerMode: false,
    autoplay: autoplay, // props로 전달된 autoplay 값 사용
    autoplaySpeed: autoplaySpeed, // props로 전달된 autoplaySpeed 값 사용
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div style={style} className={`slider-wrap ${isMobile ? "hide-dots" : ""}`}>
      <Slider {...settings}>
        {imagePaths.map((image, index) => (
          <div key={index} className="slide">
            <img
              src={image}
              alt={`Slide ${index}`}
              //   style={{ width: "100%", height: "auto", minHeight: "200px" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
