import axios from "axios";
import URL from "../constants/url";
export const getPostsData = async () => {
  try {
    const response = await axios.get(`${URL.NODE_API}/post/get-post`); // API URL
    const _postsData = response.data;
    const formattedPostsData = _postsData
      ? _postsData.map((post) => ({
          id: post.id,
          title: post.title,
          content: (post.content || "").replace(/<[^>]+>/g, "").slice(0, 40), // 태그 제거 및 처음 60자만 가져오기
          link: `/post/read/${post.link}`, // 링크를 추가
          createdAt: post.created_at,
          views: post.views,
          image: post.preview_image,
          updatedAt: post.updated_at,
          tags: post.tags || "None", // tags가 null이면 빈 배열로 변환
          target: post.target,
          source: post.source,
        }))
      : [];

    // console.log("_postsData", _postsData);

    return formattedPostsData;
  } catch (error) {
    console.error("데이터 가져오기 실패:", error);
  }
};

export const getPostDataByLink = async (link, setData) => {
  try {
    const response = await axios.get(
      `${URL.NODE_API}/post/get-by-link/${link}`
    );
    const _postsData = response.data;

    const formattedPostsData = _postsData
      ? _postsData.map((post) => ({
          id: post.id,
          link: `/post/read/${post.link}`, // 링크를 추가
          title: post.title,
          content: post.content,
          image: post.preview_image,
          tags: post.tags ? post.tags.split(",") : [], // tags가 null이면 빈 배열로 변환
          createdAt: post.created_at,
          updatedAt: post.updated_at,
          views: post.views,
        }))
      : [];
    setData(formattedPostsData[0]);
  } catch (error) {
    console.error("데이터 가져오기 실패:", error);
  }
};

export const getPostWriteDataByLink = async (link) => {
  try {
    const response = await axios.get(
      `${URL.NODE_API}/post/get-by-link/${link}`
    );
    const _postsData = response.data;

    return _postsData; // _postsData 반환
  } catch (error) {
    console.error("데이터 가져오기 실패:", error);
    return null; // 실패 시 null 반환
  }
};

export const fetchPostsData = async (postData) => {
  try {
    const response = await axios.put(
      `${URL.NODE_API}/post/update-post`,
      postData
    );
    // console.log(response);
    alert("저장되었습니다.");
  } catch (error) {
    console.error("데이터 가져오기 실패:", error);
  }
};

export const deletePostsData = async (title) => {
  try {
    const response = await axios.delete(
      `${URL.NODE_API}/post/delete-post/${title}`
    );
    console.log(response);
    alert("삭제되었습니다.");
  } catch (error) {
    console.error("데이터 가져오기 실패:", error);
  }
};

export const getTagsData = async (setTags) => {
  try {
    const response = await axios.get(`${URL.NODE_API}/post/get-tag`); // API URL
    setTags(response.data);
  } catch (error) {
    console.error("데이터 가져오기 실패:", error);
  }
};

export const getTitleData = async () => {
  try {
    const response = await axios.get(`${URL.NODE_API}/post/get-title`); // API URL
    return response.data;
    // setTags(response.data);
  } catch (error) {
    console.error("데이터 가져오기 실패:", error);
  }
};
