const MaindMapConfig = {
  collapsible: true,

  staticGraphWithDragAndDrop: false,
  nodeHighlightBehavior: true,
  directed: true,
  height: window.innerHeight * 0.65,
  width: window.innerWidth * 0.9,
  linkHighlightBehavior: true,
  panAndZoom: false,
  // initialZoom: 1.3,

  d3: {
    // alphaTarget: 0.05, // alphaTarget 설정
    // gravity: -250, // 중력 설정
    // linkLength: 120, // 링크 길이 설정
    // linkStrength: 2, // 링크 강도 설정
    disableLinkForce: false, // 링크 힘 비활성화 여부

    alphaTarget: 1,
    gravity: -200, // 중력을 약하게 설정하여 노드 간 거리를 조정
    linkLength: 100, // 링크 길이를 늘려서 노드 간의 거리를 조정
    linkDistance: 80, // 링크 거리도 늘려서 노드 간의 간격을 조절
  },
  node: {
    opacity: 1,
    symbolType: "circle",
    color: "#48C0DD",
    size: 300,
    fontSize: 12,
    labelPosition: "right", // default
    highlightFontSize: 16,
    highlightFontWeight: "bold",
    highlightStrokeColor: "blue",
    mouseCursor: "crosshair",
  },
  link: {
    fontSize: 14,
    strokeWidth: 1,
    semanticStrokeWidth: true,
    markerHeight: 12,
    markerWidth: 12,
    strokeDasharray: 12,
    highlightColor: "lightblue",
    color: "lightgray",
    type: "DASHED",
  },
};

export default MaindMapConfig;
