import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PostList = ({
  posts, // posts를 props로 받아옴
  initialCount = 9,
  loadMoreCount = Math.ceil(initialCount / 3),
  postStyle = {},
}) => {
  const [visiblePosts, setVisiblePosts] = useState([]);
  const navigate = useNavigate();

  // posts가 변경될 때 visiblePosts 초기화
  useEffect(() => {
    setVisiblePosts(posts.slice(0, initialCount));
  }, [posts, initialCount]);

  const loadMorePosts = () => {
    const nextPosts = posts.slice(
      visiblePosts.length,
      visiblePosts.length + loadMoreCount
    );
    if (nextPosts.length > 0) {
      setVisiblePosts((prevPosts) => [...prevPosts, ...nextPosts]);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      loadMorePosts();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [visiblePosts]);

  return (
    <div className="post-list-wrap">
      <div className="post-list" style={postStyle}>
        {visiblePosts.map((post) => (
          <div
            key={post.id}
            className="post-card"
            onClick={() => navigate(post.link)}
          >
            <div className="post">
              <img src={post.image} alt={post.title} />
              <div className="tags">
                {/* {post.tags.map((tag, index) => (
                  <span key={index} className="tag">
                    {tag}
                  </span>
                ))} */}
                <span className="tag">{post.tags}</span>
              </div>
              <h2>{post.title}</h2>
              <p>{post.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PostList;
