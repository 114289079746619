import React, { useState } from "react";

const DynamicTable = ({ data, columns, PAGE_SIZE = 5 }) => {
  // 열 제목 배열 추출
  const columnTitles = Object.keys(columns[0]);

  // 페이지네이션 관련 상태
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(data.length / PAGE_SIZE); // 총 페이지 수

  // URL 유효성 검사 함수
  const isValidUrl = (url) => {
    const pattern = /^(https?:\/\/[^\s]+)/; // http 또는 https 패턴
    return pattern.test(url);
  };

  // 현재 페이지의 데이터 추출
  const currentData = data.slice(
    currentPage * PAGE_SIZE,
    (currentPage + 1) * PAGE_SIZE
  );

  return (
    <div className="dynamic-table-wrap">
      <table>
        <thead>
          <tr>
            {columnTitles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columnTitles.map((title, colIndex) => (
                <td key={colIndex}>
                  {isValidUrl(row[columns[0][title]]) ? (
                    <a
                      href={row[columns[0][title]]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row[columns[0][title]]}
                    </a>
                  ) : (
                    row[columns[0][title]]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* 페이지네이션 버튼 */}
      <div>
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
          disabled={currentPage === 0}
        >
          이전
        </button>
        <span>
          {" "}
          {currentPage + 1} / {totalPages}{" "}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))
          }
          disabled={currentPage === totalPages - 1}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default DynamicTable;
