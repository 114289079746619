const ResizeImageData = (imageData, newWidth, newHeight) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = newWidth;
  canvas.height = newHeight;

  const scaleWidth = newWidth / imageData.width;
  const scaleHeight = newHeight / imageData.height;
  const scale = Math.min(scaleWidth, scaleHeight); // 비율을 유지하기 위해 최소값 사용

  const scaledWidth = imageData.width * scale;
  const scaledHeight = imageData.height * scale;
  const xOffset = (newWidth - scaledWidth) / 2;
  const yOffset = (newHeight - scaledHeight) / 2;

  ctx.fillStyle = "white"; // 배경색을 흰색으로 설정
  ctx.fillRect(0, 0, newWidth, newHeight);

  const tempCanvas = document.createElement("canvas");
  const tempCtx = tempCanvas.getContext("2d");
  tempCanvas.width = imageData.width;
  tempCanvas.height = imageData.height;
  tempCtx.putImageData(imageData, 0, 0); // 기존 이미지 데이터를 캔버스에 그립니다.

  // 스케일된 이미지를 새 캔버스에 그립니다.
  ctx.drawImage(
    tempCanvas,
    0,
    0,
    imageData.width,
    imageData.height,
    xOffset,
    yOffset,
    scaledWidth,
    scaledHeight
  );

  // 리사이즈된 이미지 데이터 반환
  return ctx.getImageData(0, 0, newWidth, newHeight);
};

const ReadPixelData = (file, width = null, height = null) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0); // (0,0) 좌표

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      if (width !== null && height !== null) {
        const resizedImageData = ResizeImageData(imageData, width, height);
        resolve(resizedImageData);
      } else {
        resolve(imageData); // imageData를 resolve로 반환
      }
    };

    image.onerror = (error) => {
      reject(error); // 오류가 발생하면 reject로 반환
    };
  });
};

export default ReadPixelData;
