import React, { useState, useRef, useCallback, useEffect } from "react";
import Webcam from "react-webcam";

function CameraRecorder() {
  const [recording, setRecording] = useState(false);
  const [inferenceEnabled, setInferenceEnabled] = useState(false); // 추론 기능 on/off 상태
  const [videoURL, setVideoURL] = useState(null);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunks = useRef([]);
  const canvasRef = useRef(null);
  const [devices, setDevices] = useState([]); // 비디오 장치 목록
  const [selectedDeviceId, setSelectedDeviceId] = useState(""); // 선택된 장치 ID

  // 장치 목록 가져오기
  useEffect(() => {
    const getDevices = async () => {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = mediaDevices.filter(
        (device) => device.kind === "videoinput"
      );
      setDevices(videoDevices);
      if (videoDevices.length > 0) {
        setSelectedDeviceId(videoDevices[0].deviceId); // 첫 번째 장치 선택
      }
    };
    getDevices();
  }, []);

  // Inference 수행 함수
  const performInference = useCallback(
    (imageData) => {
      if (inferenceEnabled) {
        console.log("Performing inference on image data", imageData);
        // 모델 추론 로직을 여기에 추가
      }
    },
    [inferenceEnabled]
  );

  // 매 프레임마다 이미지 캡처
  const captureFrame = useCallback(() => {
    if (canvasRef.current && webcamRef.current) {
      const context = canvasRef.current.getContext("2d");
      context.drawImage(
        webcamRef.current.video,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      const imageData = context.getImageData(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      performInference(imageData);
    }
    if (recording) {
      requestAnimationFrame(captureFrame);
    }
  }, [recording, performInference]);

  // 녹화 시작 함수
  const startRecording = useCallback(() => {
    const stream = webcamRef.current.stream;
    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.current.push(event.data);
      }
    };
    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(chunks.current, { type: "video/webm" });
      setVideoURL(URL.createObjectURL(blob));
      chunks.current = [];
    };
    mediaRecorderRef.current.start();
    setRecording(true);
    captureFrame(); // 매 프레임 캡처 시작
  }, [captureFrame]);

  // 녹화 정지 함수
  const stopRecording = useCallback(() => {
    mediaRecorderRef.current.stop();
    setRecording(false);
  }, []);

  // 추론 기능 on/off
  const toggleInference = () => {
    setInferenceEnabled((prev) => !prev);
  };
  // 카메라 장치 변경
  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
  };

  return (
    <div>
      <h1>Webcam Recorder</h1>
      <select onChange={handleDeviceChange} value={selectedDeviceId}>
        {devices.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Camera ${devices.indexOf(device) + 1}`}
          </option>
        ))}
      </select>
      <Webcam
        audio={false}
        ref={webcamRef}
        width={400}
        height={300}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
        }}
      />
      <canvas
        ref={canvasRef}
        width={400}
        height={300}
        style={{ display: "none" }}
      />
      <div>
        {!recording ? (
          <button onClick={startRecording}>Start Recording</button>
        ) : (
          <button onClick={stopRecording}>Stop Recording</button>
        )}
        <button onClick={toggleInference}>
          {inferenceEnabled ? "Disable Inference" : "Enable Inference"}
        </button>
      </div>
      {videoURL && (
        <div>
          <h2>Recorded Video</h2>
          <video src={videoURL} controls width="400" height="300" />
          <a href={videoURL} download="recording.webm">
            Download Video
          </a>
        </div>
      )}
    </div>
  );
}

export default CameraRecorder;
