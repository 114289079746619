import React, { useState, useEffect } from "react";
import LeafletMap from "../LeafletMap";

// 쿠키 설정 함수
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

// 쿠키 값 가져오는 함수
const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const Footer = () => {
  const [isFooterOpen, setIsFooterOpen] = useState(
    getCookie("footerOpen") === "true"
  );

  useEffect(() => {
    setCookie("footerOpen", isFooterOpen, 7);
  }, [isFooterOpen]);

  const toggleFooter = () => {
    setIsFooterOpen((prev) => !prev);
  };

  return (
    <footer className={`footer ${isFooterOpen ? "open" : "closed"}`}>
      <button className="toggle-button" onClick={toggleFooter}>
        {isFooterOpen ? "Hide Footer" : "Show Footer"}
      </button>
      {isFooterOpen && (
        <>
          <div className="footer-wrap">
            <div className="footer-content">
              <div className="footer-logo">
                <img
                  src="/images/logo/logo.png"
                  alt="IT 기술공부를 위한 웹사이트"
                  width={110}
                  height={"auto"}
                />
              </div>
              <div className="footer-sections" style={{ paddingTop: "5px" }}>
                <ul className="footer-info">
                  <li>
                    <div>
                      <strong>주소(Address)</strong>
                      <p>: 평촌역 인근</p>
                    </div>
                    <p className="add-info">
                      (581, Gyeongsu-daero, Dongan-gu, Anyang-si, Gyeonggi-do)
                    </p>
                  </li>
                  <li>
                    <div>
                      <strong>연락처(Phone)</strong>
                      <p>: (82) 010-9214-7733</p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <strong>메일(Email)</strong>
                      <p>: qkdrk777777@naver.com</p>
                    </div>
                  </li>
                </ul>
                <br />
                <ul className="footer-menu">
                  <li>이용약관(Terms of Service)</li>
                  <li>개인정보처리방침(Privacy Policy)</li>
                  <li>저작권(Copyright Policy)</li>
                </ul>
              </div>
            </div>
            <div className="footer-map">
              <LeafletMap />
            </div>
          </div>
        </>
      )}
      <div className="footer-copyright">
        <p>
          Copyright © 2024. Unauthorized use of content may result in legal
          action.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
