import React, { useState } from "react";
const Dropdown = ({ label, options }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <li className="dropdown-wrap">
      <button className="dropdown-btn" onClick={toggleDropdown}>
        {label}
      </button>
      {isDropdownOpen && (
        <ul className="dropdown">
          {options.map((option, index) => (
            <li key={index}>
              <a href={option.link} target="_blank" rel="noopener noreferrer">
                {option.label}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
export default Dropdown;
