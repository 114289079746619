const YoloToXywh = (predictions, image_width) => {
  const newData = [];
  let maxProbability = -Infinity;
  for (let i = 0; i < predictions.length; i += 7 * 30) {
    const chunk = predictions.slice(i, i + 7 * 30);
    const subArray = [];
    for (let j = 0; j < chunk.length; j += 30) {
      subArray.push(chunk.slice(j, j + 30));
      const maxInChunk = Math.max(chunk[j + 4], chunk[j + 9]);
      if (maxInChunk > maxProbability) {
        maxProbability = maxInChunk;
      }
    }
    newData.push(subArray);
  }
  const prob = maxProbability;

  let xywhData;
  let idx_x;
  let idx_y;
  for (let i = 0; i < predictions.length; i += 7 * 30) {
    const chunk = predictions.slice(i, i + 7 * 30);
    for (let j = 0; j < chunk.length; j += 30) {
      const k_chunk = chunk.slice(j, j + 30);
      if (k_chunk[4] === prob) {
        idx_y = i / 30 / 7;
        idx_x = j / 30;
        xywhData = newData[idx_y][idx_x].slice(0, 4);
      }
      if (k_chunk[9] === prob) {
        idx_y = i / 30 / 7;
        idx_x = j / 30;
        xywhData = newData[idx_y][idx_x].slice(5, 9);
      }
    }
  }

  const { 0: originalX, 1: originalY, 2: originalW, 3: originalH } = xywhData;

  const x = (idx_x + originalX) * (image_width / 7);
  const y = (idx_y + originalY) * (image_width / 7);
  const w = originalW * image_width;
  const h = originalH * image_width;

  return { x, y, w, h, prob };
};
export default YoloToXywh;
