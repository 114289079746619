import PaintBoxButton from "./PaintBoxButton";
import PaintBoxWindow from "./PaintBoxWindow";
const PaintBox = ({ isOpen, toggle }) => {
  return (
    <>
      <PaintBoxButton onClick={toggle} />
      {isOpen && <PaintBoxWindow />}
    </>
  );
};

export default PaintBox;
